import { useLocation } from 'react-router-dom';
import { FormikContextType, FormikProps, useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  setAuthReset,
  setAuthResponseReset,
  setConfirmRequest,
  singInRequest,
} from 'domains/user/reduser';
import { getError } from 'domains/user/helpers';
import { useDispatchApp } from 'redux/rootSelectors';
import { useIsRegistration, useProjectInfo } from '../../project/hooks';
import { objectOptimizationWithFormik } from '../../../utils/objectOptimizationWithFormik/objectOptimizationWithFormik';
import { useAuthResponseData, useSingInDate } from '../../user/model/selectors';

type TFormAuth = {
  email: string;
  password: string;
};

type RUseLoginDetail = FormikProps<TFormAuth> & {
  isLoading: boolean;
  isRegistration: boolean;
  notificationTitle: string;
  error: string | boolean;
  setError: (v: string) => void;
  confirm: boolean | string | null;
  openPass: boolean;
  handleToggleOpenPass: () => void;
  formikContext: FormikContextType<TFormAuth>;
  authLoading: boolean;
  project: ReturnType<typeof useProjectInfo>['data'];
};

interface TStateLocation {
  registration?: boolean;
  isForgotConfirm?: boolean;
  passChange?: boolean;
}

export const useSignInDetail = (): RUseLoginDetail => {
  const dispatch = useDispatchApp();
  const initialValues = {
    email: '',
    password: '',
  };

  const { t, i18n } = useTranslation();

  const { isLoading, data: project } = useProjectInfo();
  const location = useLocation<TStateLocation>();
  const userUpdate = localStorage.getItem('$user_update') || '';
  const params = new URLSearchParams(userUpdate);
  const hash = params.get('hash');
  const method = params.get('a');

  const { isLoading: authLoading, error: authError } = useSingInDate();

  const { data: authResponse, error: authResponseError } =
    useAuthResponseData();

  /** регистрация  */
  const { isRegistration } = useIsRegistration();

  const [error, setError] = useState<string | boolean>(false);
  const [confirm, setConfirm] = useState<boolean | string | null>(null);
  const [openPass, setOpenPass] = useState(false);

  useEffect(() => {
    if (authError.isError && authError.message !== 'error') {
      setError(() => getError(authError));
    }
    if (authResponseError.isError) {
      setError(() => getError(authResponseError));
    }
    if ((!authError.isError || !authResponseError.isError) && error) {
      setError(false);
    }
  }, [authError, authResponseError, i18n.language]);

  useEffect(() => {
    if (hash && method === 'confirmationClient') {
      dispatch(setConfirmRequest({ hash }));
    }

    return () => {
      location.state = {};
      if (authError || authResponseError) {
        dispatch(setAuthResponseReset());
        dispatch(setAuthReset());
      }
      setError(false);
    };
  }, []);

  useEffect(() => {
    if (authResponse) {
      setConfirm(() => authResponse);
    }
  }, []);

  useEffect(() => {
    let clearState: ReturnType<typeof setTimeout>;

    if (location.state && !!Object.keys(location.state).length) {
      clearState = setTimeout(() => {
        location.state = {};
        setConfirm(null);
      }, 5000);
    }

    return () => {
      if (clearState) {
        clearTimeout(clearState);
      }
    };
  }, []);

  /** текст Notice */
  const getTextNotifications = (): string => {
    if (
      location.state &&
      (location.state.registration || location.state.isForgotConfirm)
    ) {
      return t('login_page.weSendInstructions');
    }

    if (location.state && location.state.passChange) {
      return t('login_page.passChange');
    }

    return t('login_page.successfullyVerified');
  };

  const formik = useFormik<TFormAuth>({
    initialValues,
    onSubmit: (values) => {
      const param = objectOptimizationWithFormik<TFormAuth>(
        initialValues,
        values,
      );
      const token = localStorage.getItem('$token');
      const { session_token } = token
        ? JSON.parse(token)
        : { session_token: null };
      if (param) {
        const requestPayload = new Map();
        requestPayload.set('email', values.email.trim());
        requestPayload.set('password', values.password);
        requestPayload.set('domain', window.location.host);

        if (session_token) {
          requestPayload.set('session_token', session_token);
        }

        dispatch(singInRequest(Object.fromEntries(requestPayload)));
      }
    },
  });

  const handleToggleOpenPass = () => {
    setOpenPass(!openPass);
  };

  useEffect(() => {
    if (error) {
      setError(() => false);
    }
  }, [formik.values]);

  useEffect(() => {
    if (!formik.isValid) {
      formik.validateForm();
    }
  }, [i18n.language]);

  return {
    authLoading,
    isLoading,
    isRegistration,
    notificationTitle: getTextNotifications(),
    confirm,
    openPass,
    handleToggleOpenPass,
    error,
    setError,
    project,
    formikContext: formik,
    ...formik,
  };
};
