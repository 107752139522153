import React, { FC, lazy, Suspense, useEffect } from 'react';
import { Switch, Route, Redirect, useRouteMatch } from 'react-router-dom';
import { toast } from 'react-toastify';
import cn from 'classnames';
import BlackHeader from 'components/BlackHeader';
import ScrollToTopButton from 'components/ScrollToTopButton';
import { useStateValue } from 'utils/context';
import { useTranslation } from 'react-i18next';
import Loader from 'components/UI/Loader';
import { fetchPersonalInfo } from 'domains/user/actions';
import css from './styles.module.scss';
import { everyGroups } from '../../utils/statics/everyGroups';
import SearchByIdPopup from '../../components/SearchByIdPopup';
import { useUserInfo } from '../../domains/user/model/selectors';
import { useDispatchApp } from '../../redux/rootSelectors';
import { setIsShowModal } from '../../domains/search/model/reducer';

const Users = lazy(() => import('domains/users/ui'));
const UsersAPI = lazy(() => import('domains/usersAPI/ui'));
const CreateUserAPI = lazy(() => import('domains/usersAPI/ui/Create'));
const EditUserAPI = lazy(() => import('domains/usersAPI/ui/Edit'));
const Agency = lazy(() => import('domains/partners'));
const Project = lazy(() => import('domains/project'));
const ProjectSettings = lazy(() => import('domains/project/ui/Settings'));
const ProjectAdditional = lazy(() => import('domains/project/ui/Additional'));
const HistoryChanges = lazy(() => import('domains/historyChanges/ui'));
const Analysis = lazy(() => import('domains/Analysis/ui'));
const BlackList = lazy(() => import('domains/blackList/ui'));
const BlackLists = lazy(() => import('domains/blackLists/ui'));
const Error404 = lazy(() => import('domains/app/Errors/Error404'));
const NoAccess = lazy(() => import('domains/app/Errors/noAccess'));
const NC = lazy(() => import('domains/app/Errors/NoConnection'));

const ControlPanel: FC = () => {
  const match = useRouteMatch();

  const dispatch = useDispatchApp();
  const { t } = useTranslation();
  useEffect(() => {
    const viewModeHide = localStorage.getItem('$viewModeHide');
    const Text = () => (
      <div>
        {t('alerts.view_mode')}
        <br />
        {t('alerts.view_mode_cont')}
      </div>
    );

    if (!viewModeHide) {
      toast(<Text />, {
        type: 'info',
        delay: 500,
        autoClose: 5000,
        onClose: () => {
          localStorage.setItem('$viewModeHide', 'true');
        },
      });
    }

    if (user === null) {
      dispatch(fetchPersonalInfo());
    }

    return () => localStorage.setItem('$viewModeHide', 'true');
  }, []);

  document.addEventListener('keydown', (event) => {
    if (event.code === 'KeyK' && (event.ctrlKey || event.metaKey)) {
      event.preventDefault();
      dispatch(setIsShowModal(true));
    }
  });

  const { state } = useStateValue();
  const { data: user, isLoading } = useUserInfo();

  return (
    <div className={cn(css.wrapper, { extend: state.theme.containerToggle })}>
      {isLoading && <Loader />}
      {!isLoading && user && (
        <>
          <BlackHeader />
          <SearchByIdPopup />
          <Switch>
            {everyGroups(user, 'manager') && (
              <Route exact path={`${match.path}/users`}>
                <Suspense fallback={<Loader />}>
                  <Users />
                </Suspense>
              </Route>
            )}
            {everyGroups(user, 'manager', 'owner') && (
              <Route exact path={`${match.path}/users_api`}>
                <Suspense fallback={<Loader />}>
                  <UsersAPI />
                </Suspense>
              </Route>
            )}
            {everyGroups(user, 'manager', 'owner') && (
              <Route exact path={`${match.path}/users_api/create`}>
                <Suspense fallback={<Loader />}>
                  <CreateUserAPI />
                </Suspense>
              </Route>
            )}
            {everyGroups(user, 'manager', 'owner') && (
              <Route exact path={`${match.path}/users_api/edit`}>
                <Suspense fallback={<Loader />}>
                  <EditUserAPI />
                </Suspense>
              </Route>
            )}
            {everyGroups(user, 'manager') && (
              <Route exact path={`${match.path}/partners`}>
                <Suspense fallback={<Loader />}>
                  <Agency />
                </Suspense>
              </Route>
            )}
            {everyGroups(user, 'manager', 'owner') && (
              <Route exact path={`${match.path}/project`}>
                <Suspense fallback={<Loader />}>
                  <Project />
                </Suspense>
              </Route>
            )}
            {everyGroups(user, 'manager', 'owner') && (
              <Route exact path={`${match.path}/project/settings`}>
                <Suspense fallback={<Loader />}>
                  <ProjectSettings />
                </Suspense>
              </Route>
            )}
            {everyGroups(user, 'manager', 'owner') && (
              <Route exact path={`${match.path}/project/additional`}>
                <Suspense fallback={<Loader />}>
                  <ProjectAdditional />
                </Suspense>
              </Route>
            )}
            {everyGroups(user, 'manager', 'owner') && (
              <Route exact path={`${match.path}/history_changes`}>
                <Suspense fallback={<Loader />}>
                  <HistoryChanges />
                </Suspense>
              </Route>
            )}
            {everyGroups(user, 'manager', 'owner') && (
              <Route exact path={`${match.path}/analysis`}>
                <Suspense fallback={<Loader />}>
                  <Analysis />
                </Suspense>
              </Route>
            )}
            <Route exact path={`${match.path}/blackLists`}>
              <Suspense fallback={<Loader />}>
                {everyGroups(user, 'manager', 'owner') ? (
                  <BlackLists />
                ) : (
                  <NoAccess />
                )}
              </Suspense>
            </Route>
            <Route exact path={`${match.path}/blackLists/:id`}>
              <Suspense fallback={<Loader />}>
                {everyGroups(user, 'manager', 'owner') ? (
                  <BlackList />
                ) : (
                  <NoAccess />
                )}
              </Suspense>
            </Route>
            <Redirect exact from="/control_panel/" to={`${match.path}/users`} />
            <Route exact path={`${match.path}/error/nc`}>
              <Suspense fallback={<Loader />}>
                <NC />
              </Suspense>
            </Route>
            <Route>
              <Suspense fallback={<Loader />}>
                <Error404 />
              </Suspense>
            </Route>
          </Switch>
          <ScrollToTopButton />
        </>
      )}
    </div>
  );
};

export default ControlPanel;
