import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

type ErrorCode =
  | '1'
  | '40'
  | '50'
  | '2010'
  | '2011'
  | '2020'
  | '2021'
  | '2030'
  | '2031'
  | '2032'
  | '2033'
  | '2034'
  | '20340'
  | '2035'
  | '2026'
  | '2040'
  | '2041'
  | '2050'
  | '2051'
  | '2052'
  | '2053'
  | '2054'
  | '2060'
  | '2061'
  | '2062'
  | '2063'
  | '200032'
  | '-20001'
  | '-20002';

type ErrorDict = Record<ErrorCode, string>;

type Props = {
  code: number | undefined;
};

const ErrorDictionary: FC<Props> = ({ code }) => {
  const { t } = useTranslation();

  const errorList: ErrorDict = {
    '1': t('errors.err1'),
    '40': t('errors.err40_link'),
    '50': t('errors.err50'),
    '2010': t('errors.err2010'),
    '2011': t('errors.err2011'),
    '2020': t('errors.err2020'),
    '2021': t('errors.err2021'),
    '2030': t('errors.err2030'),
    '2031': t('errors.err2031'),
    '2032': t('errors.err2032'),
    '2033': t('errors.err2033'),
    '2034': t('errors.err2034'),
    '20340': t('errors.upload.e5'),
    '2035': t('errors.err2035'),
    '2026': t('errors.err2026'),
    '2040': t('errors.err2040'),
    '2041': t('errors.err2041'),
    '2050': t('errors.err2050'),
    '2051': t('errors.err2051'),
    '2052': t('errors.err2052'),
    '2053': t('errors.err2053'),
    '2054': t('errors.err2054'),
    '2060': t('errors.err2060'),
    '2061': t('errors.err2061'),
    '2062': t('errors.err2062'),
    '2063': t('errors.err2063'),
    '200032': t('errors.upload.e4'),
    '-20001': t('clients_page.client_create.hint2'),
    '-20002': t('clients_page.client_create.hint3'),
  };
  const [response, setResponse] = useState<string>('');
  const isErrorDict = (v: string): v is ErrorCode => true;
  useEffect(() => {
    if (isErrorDict(`${code}`)) {
      setResponse(() => errorList[`${code}`]);
    } else {
      setResponse(() => 'неизвестная ошибка');
    }
  }, [code]);

  return <>{response}</>;
};

export default ErrorDictionary;
