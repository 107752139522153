import moment from 'moment';
import { put, delay } from '@redux-saga/core/effects';
import { call, select, take } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';
import i18next from 'i18next';
import { TCloneTarget, TDates, TStatisticType } from 'types/general';
import {
  setTagCampaignActive,
  setTagsCampaigns,
} from 'domains/search/model/reducer';
import {
  editPersonalCampaign,
  fetchCampaingTranslationApi,
} from 'domains/campaign/api';
import {
  selectorCampaignsIcons,
  selectorPartnerStatisticFieldList,
} from 'domains/campaigns/model/selectors';
import { TStatisticsFields } from 'domains/campaign/types';
import { PeriodEnum } from 'types/containers';
import {
  createCampaignAction,
  fetchCampaigns,
  fetchCampaignsStatistic,
  fetchCampaignsTranslation,
  fetchCampaignsStatisticWeek,
  setCampaignsListModal,
  setCampaignsListView,
  setCampaignsPeriod,
  setIsShowSortFilter,
  setListSort,
  setTempFetchCampaigns,
  setTempFetchCampaignsSuccess,
  setTempFetchStatistic,
  setTempFetchStatisticWeek,
  setToggleStatusCampaign,
  setTempFetchTranslation,
  setCampaignTranslationFilter,
  setChartStatistic,
  fetchChartStatistic,
  setLastStatus,
  setTotal,
  setStatisticFieldList,
  fetchCampaignsTotal,
  setCampaignsIcons,
  setCampaignsIcon,
  clearCampaignsIcon,
  fetchStatisticType,
  setStatisticType,
} from '../reducer';
import {
  extractGenFetchData,
  genFetchedData,
} from '../../../redux/fetchedData';
import updateToken from '../../../utils/updateToken';
import {
  addCampaign,
  campaignClone,
  fetchCampaignsApi,
  fetchStatisticsApi,
  fetchStatisticsWeekApi,
  setCampingChangeStatus,
  setStatusesInCampaignList,
  setStatusesInPartnerCampaigns,
  tagsSetInCampaign,
  targetingExportItemsToExcel,
  fetchTranslationApi,
  fetchChartStatisticApi,
  geoExportItemsToCSV,
  partnerGetStatisticFieldList,
  setPartnerStatisticsApi,
  getTotalListStat,
  getIconsList,
  getStatisticType,
} from '../api';
import { parsingDate } from '../../../utils/momentParse';
import {
  makeReqWithRD,
  TMakeReqWithRD,
  updateFillFetchedData,
} from '../../../redux/makeReqWithRD';
import {
  changeGroupCampaigns,
  cloneCampaignsToClient,
  doubleCampaigns,
  exportItemsToExcel,
  fetchCampaignsListModal,
  fetchStatisticFieldList,
  loadCampaignsListView,
  searchCampaignListView,
  setPartnerStatisticsFields,
} from '../model/actions';
import { extractMakeGenList, makeGenList } from '../../../redux/makeGenList';
import {
  selectorCampaignsFilters,
  selectorCampaignsLastID,
  selectorCampaignsList,
  selectorCampaignsView,
  selectorCampaignsTranslation,
  selectorFiltredCampaignsTranslation,
} from '../model/selectors';
import {
  selectorBreadcrumbs,
  selectorCampaignsActive,
} from '../../search/model/selectors';
import { setCampaignsTabs } from '../../storage/model/reducer';
import {
  PreResponseStatisticsCampaignsWeek,
  RCampaignsArray,
  RCampaignsArrayStata,
  ResponseCampaignsStata,
  ResponseTranslation,
  RStatisticsCampaigns,
  RStatisticsCampaignsWeek,
  TCampaignsFilter,
  TRCloneCampaigns,
  WeekStatistic,
} from '../types';
import { selectorUser, selectorUserSettings } from '../../user/model/selectors';
import { setTagCampaignActiveDefault } from '../../search/model/actions';
import { genTimePeriod } from '../../../utils/genTimePeriod';
import { CampaignFilter } from '../model/shared/enums';
import { compareField } from '../model/utils/comparefield';
import { toastError, toastSuccess } from '../../../utils/ToastUtils/toastUtils';
import { ResponseUser, SettingConfig } from '../../user/types';
import { selectorCampaignsTabs } from '../../storage/model/selectors';
import { toastSuccessDoubled } from '../../../components/CopyModal/utils/toastError';
import { getSettingsValue } from '../../user/model/utils/searhFieldIDbyName';
import { setStoragesIcons } from '../localStorage/icons';

export function* workerFetchCampaignsSaga({
  payload,
}: ReturnType<typeof fetchCampaigns>): SagaIterator<void> {
  try {
    const { dates, updateDates, ...rest } = payload;

    yield call<TMakeReqWithRD<typeof fetchCampaignsApi>>(makeReqWithRD, {
      fetcher: fetchCampaignsApi,
      fill: (v) => {
        const data = v.get('data');
        const newData: RCampaignsArrayStata = data
          ? data.map((e) => ({ ...e, statistics: {} }))
          : [];
        return setTempFetchCampaigns(
          updateFillFetchedData<RCampaignsArray, RCampaignsArrayStata>({
            fillFetchedData: v,
            newData,
          }),
        );
      },
      fillSuccess: (v) => {
        const data = v.get('data');
        const newData: RCampaignsArrayStata = data
          ? data.map((e) => ({ ...e, statistics: {} }))
          : [];
        return setTempFetchCampaignsSuccess(
          updateFillFetchedData<RCampaignsArray, RCampaignsArrayStata>({
            fillFetchedData: v,
            newData,
          }),
        );
      },
      parameters: rest.filters
        ? {
            partner_xxhash: rest.partner_xxhash,
            filters: rest.filters,
          }
        : {
            partner_xxhash: rest.partner_xxhash,
          },
    });
    yield put(loadCampaignsListView({ isHard: true }));
    const itemsTemp = yield select(selectorCampaignsList);
    const items = itemsTemp.get('data') as RCampaignsArrayStata;
    let datesList: TDates['period'] = {
      to: '',
      from: '',
    };
    if (items && items.length) {
      datesList = {
        to: items[0].date_end.date,
        from: items[0].date_start.date,
      };
      items.forEach((camp) => {
        if (camp.date_start.date < datesList.from) {
          datesList = { ...datesList, from: camp.date_start.date };
        }
        if (datesList.to && camp.date_end.date > datesList.to) {
          datesList = { ...datesList, to: camp.date_end.date };
        }
      });
      yield put(setCampaignsPeriod(datesList));
    }

    const xxhash_list = items.map((e) => e.xxhash);
    if (!(xxhash_list.length > 0 && dates)) return;
    if (dates.type === PeriodEnum.WEEK) {
      yield put(
        fetchCampaignsStatisticWeek({
          xxhash_list,
          period: {
            from: parsingDate(moment().subtract(6, 'days')).format(
              'YYYY-MM-DD',
            ),
            to: parsingDate(new Date()).format('YYYY-MM-DD'),
          },
        }),
      );
      return;
    }
    if (dates.type === 'all_time' && updateDates) {
      updateDates(
        {
          to: parsingDate(datesList.to).format('YYYY-MM-DD'),
          from: parsingDate(datesList.from).format('YYYY-MM-DD'),
        },
        'all_time',
      );
    }
    yield put(
      fetchCampaignsStatistic({
        xxhash_list,
        period:
          dates.type === 'all_time'
            ? {
                to: parsingDate(datesList.to).format('YYYY-MM-DD'),
                from: parsingDate(datesList.from).format('YYYY-MM-DD'),
              }
            : dates.period,
      }),
    );
  } catch (e) {
    console.error({ e });
  }
}

export function* workerFetchChartStatisticsSaga({
  payload,
}: ReturnType<typeof fetchChartStatistic>): SagaIterator<void> {
  try {
    yield call<TMakeReqWithRD<typeof fetchChartStatisticApi>>(makeReqWithRD, {
      fetcher: fetchChartStatisticApi,
      fill: setChartStatistic,
      parameters: { ...payload },
    });
  } catch (e) {
    console.error({ e });
  }
}

export function* workerFetchStatisticTypeSaga({
  payload,
}: ReturnType<typeof fetchStatisticType>): SagaIterator<void> {
  let fetchedData = genFetchedData<TStatisticType[]>(null).set(
    'isLoading',
    true,
  );
  yield put(setStatisticType(fetchedData));
  try {
    const result = yield call(getStatisticType, {...payload});
    fetchedData = fetchedData.set('data', result);
    yield put(setStatisticType(fetchedData));
  } catch (e) {
    console.error({ e });
  } finally {
    fetchedData = fetchedData.set('isLoading', false).set('LTU', Date.now());
    yield put(setStatisticType(fetchedData));
  }
} 

export function* workerFetchCampaignsStatisticsSaga({
  payload,
}: ReturnType<typeof fetchCampaignsStatistic>): SagaIterator<void> {
  const { xxhash_list_total, ...parameters } = payload;
  try {
    yield put(
      fetchCampaignsTotal({
        xxhash_list: xxhash_list_total || parameters.xxhash_list,
        period: parameters.period,
      }),
    );

    yield call<TMakeReqWithRD<typeof fetchStatisticsApi>>(makeReqWithRD, {
      fetcher: fetchStatisticsApi,
      fill: setTempFetchStatistic,
      parameters,
    });

    const listTemp = yield select(
      ({ campaignsReducer }) => campaignsReducer.tempCampaigns,
    );
    const list = listTemp.get('data');

    const tempStatistics = yield select(
      ({ campaignsReducer }) => campaignsReducer.tempStatistic,
    );

    let statistics: RStatisticsCampaigns = tempStatistics.get('data');

    // начало костыля
    const tempBreadcrumbs = yield select(selectorBreadcrumbs);
    const breadcrumbs = tempBreadcrumbs.get('data');
    if (
      breadcrumbs &&
      breadcrumbs &&
      'Client' in breadcrumbs &&
      breadcrumbs.Client.xxhash === '6566B07B891EE6A0' &&
      statistics
    ) {
      statistics = {
        ...statistics,
        '6566B07B891EE6A0': {
          ...statistics['6566B07B891EE6A0'],
          cpc: 0,
          cpm: 0,
          ctr: 0,
          spent: 0,
        },
      };
      const newTempStatistics = tempStatistics.set('data', statistics);
      yield put(setTempFetchStatistic(newTempStatistics));
    }
    // конец костыля
    if (list && statistics) {
      const keys = Object.keys(statistics.total);
      const newData = list.map((el: ResponseCampaignsStata) => {
        const obj = {};
        const stata = statistics[el.xxhash];
        if (stata) {
          keys.forEach((k) => {
            if (k !== 'xxhash') {
              obj[k] = stata[k] || 0;
            }
          });
        } else {
          keys.forEach((k) => {
            if (k !== 'xxhash') {
              obj[k] = 0;
            }
          });
        }
        return { ...el, statistics: obj };
      });
      yield put(setTempFetchCampaigns(listTemp.set('data', newData)));
      yield put(setIsShowSortFilter(true));
    }
  } catch (e) {
    console.error({ e });
  }
}

export function* workerFetchCampaignsStatisticsWeekSaga({
  payload,
}: ReturnType<typeof fetchCampaignsStatisticWeek>): SagaIterator<void> {
  const { xxhash_list_total, ...parameters } = payload;
  try {
    const tempBreadcrumbs = yield select(selectorBreadcrumbs);
    const breadcrumbs = tempBreadcrumbs.get('data');
    const arPeriod = genTimePeriod(7);
    const mockWeekStatistics = {
      bid_statistic: 0,
      click: 0,
      cpc: 0,
      cpm: 0,
      ctr: 0,
      show: 0,
      spent: 0,
      profit: 0,
      actions: 0,
      aclick: 0,
      vclick: 0,
      act1: 0,
      act2: 0,
      act3: 0,
      act4: 0,
      act5: 0,
      act6: 0,
    };
    yield put(
      fetchCampaignsTotal({
        xxhash_list: xxhash_list_total || parameters.xxhash_list,
        period: parameters.period,
      }),
    );
    yield call<TMakeReqWithRD<typeof fetchStatisticsWeekApi>>(makeReqWithRD, {
      fetcher: fetchStatisticsWeekApi,
      fill: (v) => {
        const data: PreResponseStatisticsCampaignsWeek | null = v.get('data');
        if (!data) {
          return setTempFetchStatisticWeek(
            genFetchedData<RStatisticsCampaignsWeek>(null).set(
              'isLoading',
              true,
            ),
          );
        }
        const dataKeys = Object.keys(data);
        const newData =
          data &&
          dataKeys.map((key) => {
            const currentItem = data[key];
            const newItems = {} as WeekStatistic;
            if (currentItem.total) {
              newItems.total = currentItem.total;
            }
            if (Object.keys(currentItem).length) {
              arPeriod.forEach((date) => {
                if (date in currentItem) {
                  // условие костыля
                  if (
                    breadcrumbs &&
                    'Client' in breadcrumbs &&
                    breadcrumbs.Client.xxhash === '6566B07B891EE6A0'
                  ) {
                    newItems[date] = {
                      ...currentItem[date],
                      cpc: 0,
                      cpm: 0,
                      ctr: 0,
                      spent: 0,
                    };
                  } else {
                    newItems[date] = currentItem[date];
                  }
                } else {
                  newItems[date] = mockWeekStatistics;
                }
              });
            }

            return {
              xxhash: key,
              items: newItems,
            };
          });
        return setTempFetchStatisticWeek(
          updateFillFetchedData<
            PreResponseStatisticsCampaignsWeek,
            RStatisticsCampaignsWeek
          >({ fillFetchedData: v, newData }),
        );
      },
      parameters,
    });
  } catch (e) {
    console.error({ e });
  }
}

export function* workerFetchCampaignsTranslationSaga({
  payload,
}: ReturnType<typeof fetchCampaignsTranslation>): SagaIterator<void> {
  try {
    yield call<TMakeReqWithRD<typeof fetchTranslationApi>>(makeReqWithRD, {
      fetcher: fetchTranslationApi,
      fill: setTempFetchTranslation,
      parameters: { ...payload },
    });
  } catch (e) {
    console.error({ e });
  }
}

export function* onToggleStatusCampaignSaga({
  payload,
}: ReturnType<typeof setToggleStatusCampaign>): SagaIterator<void> {
  const { xxhash, translation, status, reRenderParams, setter, value } =
    payload;
  try {
    yield call(updateToken);
    const result = yield call(setCampingChangeStatus, { xxhash, status });
    if (result) {
      setter(!value);
      if (reRenderParams) {
        const { alertText, isRerender, statusCampaigns, client_id } =
          reRenderParams;
        if (isRerender) {
          toastSuccess(alertText);
          const userTemp = yield select(selectorUser);

          const user = userTemp.get('data');
          yield put(
            fetchCampaigns({
              partner_xxhash:
                !client_id ? user.partner.xxhash : client_id,
              filters: {
                status: statusCampaigns,
              },
            }),
          );
        }
      } else {
        const campaignsAllTemp = yield select(selectorCampaignsList);
        const campaigns = campaignsAllTemp.get('data') as RCampaignsArrayStata;
        yield put(
          setTempFetchCampaigns(
            campaignsAllTemp.set(
              'data',
              campaigns.map((val) =>
                val.xxhash === xxhash ? { ...val, status } : val,
              ),
            ),
          ),
        );
      }
      if (translation) {
        const campaignsTranslationTemp = yield select(
          selectorCampaignsTranslation,
        );
        const campaignsTranslationData = campaignsTranslationTemp.get(
          'data',
        ) as Record<string, ResponseTranslation>;
        campaignsTranslationData[xxhash] = yield call(
          fetchCampaingTranslationApi,
          {
            xxhash,
          },
        );
        const data = campaignsTranslationData;
        if (status === 'LAUNCHED' || status === 'STOPPED') {
          yield put(setTempFetchTranslation(genFetchedData(data)));
        }
      }
    }
  } catch (err) {
    console.error({ err });
  }
}

export function* workerCreateCampaignSaga({
  payload,
}: ReturnType<typeof createCampaignAction>): SagaIterator<void> {
  let fetchedData = genFetchedData<RCampaignsArrayStata>(null).set(
    'isLoading',
    true,
  );
  yield put(setTempFetchCampaigns(fetchedData));
  try {
    const { callbacks, tags, ...rest } = payload;
    yield call(updateToken);
    const result: string = yield call(addCampaign, rest);

    if (result) {
      yield call(tagsSetInCampaign, {
        xxhash: result,
        tags,
      });
      yield put(setLastStatus('STOPPED'));
      yield put(setCampaignsTabs('STOPPED'));
      callbacks.goBack(result);
      toastSuccess(
        i18next.t('campaigns_page.create_campaign.camp_create_hint'),
      );
    }
  } catch (e) {
    const code = e?.code ?? 0;
    fetchedData = fetchedData.set('error', {
      isError: true,
      message: e.message ?? '',
      code,
      fields: e.data.fields,
    });
    yield put(setTempFetchCampaigns(fetchedData));
    toastError(e.data.description);
  } finally {
    fetchedData = fetchedData.set('isLoading', false).set('LTU', Date.now());
    yield put(setTempFetchCampaigns(fetchedData));
  }
}

export function* workerSearchTagsCampaign({
  payload,
}: ReturnType<typeof setTagCampaignActiveDefault>): SagaIterator<void> {
  if (payload) {
    yield put(setTagsCampaigns([]));
  }
  yield put(setTagCampaignActive([]));
}

export function* sortCampaignListWorker({
  payload,
}: ReturnType<typeof setListSort>): SagaIterator<void> {
  try {
    const { sort, key } = payload;

    const tempList = yield select(selectorCampaignsList);
    const { data: campaignsList } =
      extractGenFetchData<RCampaignsArrayStata>(tempList);
    const filtredTranslationCampaignData: RCampaignsArrayStata = yield select(
      selectorFiltredCampaignsTranslation,
    );
    const list =
      filtredTranslationCampaignData && campaignsList
        ? [...filtredTranslationCampaignData].map((item) => ({
            ...item,
            statistics: {
              ...campaignsList.find(
                (statItem) => statItem.xxhash === item.xxhash,
              )?.statistics,
            },
          }))
        : campaignsList && [...campaignsList];
    let newData: RCampaignsArrayStata = [];
    if (list) {
      if (key === 'budget' && sort) {
        if (sort === 'ASC') {
          newData = list.sort(
            (
              a: { limits: { budget: { total: number } } },
              b: { limits: { budget: { total: number } } },
            ) =>
              Number(a.limits.budget.total) > Number(b.limits.budget.total)
                ? 1
                : -1,
          );
        } else {
          newData = list.sort(
            (
              a: { limits: { budget: { total: number } } },
              b: { limits: { budget: { total: number } } },
            ) =>
              Number(a.limits.budget.total) < Number(b.limits.budget.total)
                ? 1
                : -1,
          );
        }
      } else if (key === 'name' && sort) {
        if (sort === 'ASC') {
          newData = list.sort((a, b) => {
            const titleA = a.title.toLowerCase();
            const titleB = b.title.toLowerCase();
            return titleA < titleB ? -1 : 1;
          });
        } else {
          newData = list.sort((a, b) => {
            const titleA = a.title.toLowerCase();
            const titleB = b.title.toLowerCase();
            return titleA > titleB ? -1 : 1;
          });
        }
      } else if (typeof key === 'string' && sort) {
        if (sort === 'DESC') {
          newData = list.sort(
            (
              a: { statistics: { [x: string]: number } },
              b: { statistics: { [x: string]: number } },
            ) => (a.statistics[key] < b.statistics[key] ? 1 : -1),
          );
        } else {
          newData = list.sort(
            (
              a: { statistics: { [x: string]: number } },
              b: { statistics: { [x: string]: number } },
            ) => (a.statistics[key] > b.statistics[key] ? 1 : -1),
          );
        }
      } else {
        newData = list.sort((a, b) => {
          const dateStartA = +moment(a.date_start.date);
          const dateStartB = +moment(b.date_start.date);
          const dateA = +moment(a.date_create.date);
          const dateB = +moment(b.date_create.date);
          if (dateStartA === dateStartB) {
            return dateA > dateB ? -1 : 1;
          }
          return dateStartA > dateStartB ? -1 : 1;
        });
      }
    }
    if (filtredTranslationCampaignData) {
      yield put(setCampaignTranslationFilter(newData));
    } else {
      yield put(
        setTempFetchCampaignsSuccess(
          tempList.set('data', newData).set('LTU', Date.now()),
        ),
      );
    }
    yield put(loadCampaignsListView({ isHard: true, saveLength: true }));
  } catch (e) {
    console.error({ e });
  }
}

export function* setCampaignsFilterWorker(): SagaIterator<void> {
  try {
    yield put(loadCampaignsListView({ isHard: true }));
  } catch (e) {
    console.error(e);
  }
}

export function* loadCampaignsListWorker({
  payload,
}: ReturnType<typeof loadCampaignsListView>): SagaIterator<void> {
  try {
    const { isHard = false, saveLength = false } = payload;

    const temp = yield select(selectorCampaignsList);
    if (temp.get('isLoading')) {
      yield take(setTempFetchCampaignsSuccess);
    }
    const newTemp = yield select(selectorCampaignsList);
    const filter: TCampaignsFilter[] = yield select(selectorCampaignsFilters);
    const dataCampaign = newTemp.get('data') as RCampaignsArrayStata;
    const filtredTranslationCampaignData: RCampaignsArrayStata = yield select(
      selectorFiltredCampaignsTranslation,
    );
    const data = filtredTranslationCampaignData || dataCampaign;
    let dataFilter: RCampaignsArrayStata = [];
    if (filter.length) {
      data.forEach((camp) => {
        const isCompare: boolean[] = [];
        filter.forEach(({ key, compare, value }) => {
          if (
            (key === CampaignFilter.CAMPAIGN_START ||
              key === CampaignFilter.CAMPAIGN_FINISH) &&
            compareField({
              value:
                key === CampaignFilter.CAMPAIGN_START
                  ? camp.date_start.date
                  : camp.date_end.date,
              compareKey: compare,
              compareValue: moment(value).format('YYYY.MM.DD'),
              isDate: true,
            })
          ) {
            isCompare.push(true);
          } else {
            isCompare.push(false);
          }
        });
        if (!isCompare.includes(false)) {
          dataFilter.push(camp);
        }
      });
    } else {
      dataFilter = data;
    }
    yield put(setCampaignTranslationFilter(dataFilter));
    const lastID = yield select(selectorCampaignsLastID);

    const { listView: listPrev } = extractMakeGenList<RCampaignsArrayStata>(
      yield select(selectorCampaignsView),
    );
    const counterLength = listPrev.length;

    if (isHard) {
      const genList = makeGenList<RCampaignsArrayStata>();
      yield put(setCampaignsListView(genList));
    }
    if (dataFilter) {
      const { listView: prev } = extractMakeGenList<RCampaignsArrayStata>(
        yield select(selectorCampaignsView),
      );
      const finishIndex = prev.length + (saveLength ? counterLength : 20);
      const startIndex = prev.length;
      let newList = makeGenList<RCampaignsArrayStata>();
      const indexLastID = data.findIndex(({ xxhash }) => xxhash === lastID) + 5;
      if (lastID && indexLastID > finishIndex) {
        newList = newList.set('listView', [
          ...prev,
          ...dataFilter.filter(
            (item, index) => index >= startIndex && index <= indexLastID,
          ),
        ]);
      } else {
        newList = newList.set('listView', [
          ...prev,
          ...dataFilter.filter(
            (item, index) => index >= startIndex && index < finishIndex,
          ),
        ]);
      }
      yield put(setCampaignsListView(newList));
    }
  } catch (e) {
    console.error({ e });
  }
}

export function* searchCampaignListWorker({
  payload,
}: ReturnType<typeof searchCampaignListView>): SagaIterator<void> {
  const { callbacks, blockTotalRequest, search, period } = payload;
  try {
    let genList = makeGenList<RCampaignsArrayStata>();
    const state = yield select(selectorCampaignsList);
    const { data } = extractGenFetchData<RCampaignsArrayStata>(state);
    // этот кусоек отвечает за корректное применение поиска при смене вкладки рк
    const viewState = yield select(selectorCampaignsView);
    const { listView } = extractMakeGenList<RCampaignsArrayStata>(viewState);

    const filtredTranslationCampaignData: RCampaignsArrayStata = yield select(
      selectorFiltredCampaignsTranslation,
    );

    const currentData = filtredTranslationCampaignData || data;

    genList = genList.set('listView', listView || []);
    if (callbacks?.setIsLoadingSearch) {
      callbacks.setIsLoadingSearch(true);
    }
    yield put(setCampaignsListView(genList));
    yield delay(100);
    // этот кусоек отвечает за корректное применение поиска при смене вкладки рк

    if (search) {
      if (currentData) {
        const campaignList = currentData.filter(({ title }) =>
          title.toLowerCase().includes(search.toLowerCase()),
        );
        genList = genList.set('listView', campaignList).set('isBlocked', true);
        yield put(setCampaignsListView(genList));
        if (!blockTotalRequest && campaignList) {
          yield put(
            fetchCampaignsTotal({
              xxhash_list: campaignList?.map((item) => item.xxhash),
              period,
              debounce: 1000,
            }),
          );
        }
      }
    } else {
      yield put(loadCampaignsListView({ isHard: true }));
      if (!blockTotalRequest && currentData)
        yield put(
          fetchCampaignsTotal({
            xxhash_list: currentData.map((item) => item.xxhash),
            period,
            debounce: 1000,
          }),
        );
    }
    if (callbacks?.setIsLoadingSearch) {
      callbacks.setIsLoadingSearch(false);
    }
  } catch (e) {
    console.error({ e });
    if (callbacks?.setIsLoadingSearch) {
      callbacks.setIsLoadingSearch(false);
    }
  }
}

export function* filterCampaignsTagsWorker(): SagaIterator<void> {
  try {
    const { data } = extractGenFetchData<RCampaignsArrayStata>(
      yield select(selectorCampaignsList),
    );

    const filtredTranslationCampaignData: RCampaignsArrayStata = yield select(
      selectorFiltredCampaignsTranslation,
    );

    const currentData = filtredTranslationCampaignData || data;

    const activeTag: string[] = yield select(selectorCampaignsActive);
    if (!!activeTag.length && currentData) {
      const genList = makeGenList<RCampaignsArrayStata>([
        ...currentData.filter((campaign) =>
          campaign.title
            .split('/')
            .map((tag) => tag.trim())
            .some((tag) =>
              activeTag.map((v) => v.toLowerCase()).includes(tag.toLowerCase()),
            ),
        ),
      ]).set('isBlocked', true);
      yield put(setCampaignsListView(genList));
    } else {
      yield put(loadCampaignsListView({ isHard: true }));
    }
  } catch (e) {
    console.error({ e });
  }
}

export function* fetchCampaignsListModalSagaWorker({
  payload,
}: ReturnType<typeof fetchCampaignsListModal>): SagaIterator<void> {
  try {
    yield call<TMakeReqWithRD<typeof fetchCampaignsApi>>(makeReqWithRD, {
      fetcher: fetchCampaignsApi,
      fill: setCampaignsListModal,
      parameters: {
        partner_xxhash: payload.partner_xxhash,
        filters: payload.filters,
      },
    });
  } catch (e) {
    console.error({ e });
  }
}

export function* changeStatusGroupCampaignsWorker({
  payload,
}: ReturnType<typeof changeGroupCampaigns>): SagaIterator<void> {
  try {
    const {
      mode,
      client_xxhash,
      alertText,
      callback,
      campaign_xxhash_list,
      status,
      filter,
    } = payload;
    let result = false;
    if (mode === 'GROUP' || !client_xxhash) {
      result = yield call(setStatusesInCampaignList, {
        status,
        campaign_xxhash_list,
      });
    }
    if (mode === 'ALL') {
      result = yield call(setStatusesInPartnerCampaigns, {
        status,
        xxhash: client_xxhash,
      });
    }
    if (result) {
      callback();
      if (client_xxhash) {
        yield put(
          fetchCampaigns({
            partner_xxhash: client_xxhash,
            filters: {
              status: filter,
            },
          }),
        );
      } else {
        const { data: user } = extractGenFetchData<ResponseUser>(
          yield select(selectorUser),
        );
        yield put(
          fetchCampaigns({
            partner_xxhash: user?.partner.xxhash,
            filters: {
              status: filter,
            },
          }),
        );
      }
      toastSuccess(alertText);
    }
  } catch (e) {
    console.error(e);
  }
}

export function* copyCampaignsToOtherClientWorker({
  payload,
}: ReturnType<typeof cloneCampaignsToClient>): SagaIterator<void> {
  const {
    from,
    to,
    setWarningList,
    setCopying,
    setProgress,
    setIsWarning,
    forced,
  } = payload;
  try {
    const campaignsListError: TCloneTarget[] = [];
    const result: TRCloneCampaigns = yield call(campaignClone, {
      campaign_xxhash_list: from.map((item) => item.xxhash),
      client_xxhash_list: to,
      forced,
    });
    result.forEach((campaign) => {
      if (!campaign.status) {
        campaignsListError.push({
          xxhash: campaign.xxhash_campaign,
          status: campaign.status,
          data: {},
          message: campaign.message,
        });
      }
      campaign.targeting_info.forEach((target) => {
        if (!target.status) {
          campaignsListError.push(target);
        }
      });
      campaign.creative_info.forEach((creative) => {
        creative.targeting_info.forEach((target) => {
          if (!target.status) {
            campaignsListError.push(target);
          }
        });
      });
    });
    if (campaignsListError.length) {
      setIsWarning(true);
    } else {
      setIsWarning(false);
    }
    setWarningList(campaignsListError);
  } catch (e) {
    console.error(e);
    setIsWarning(true);
    setWarningList([e]);
  } finally {
    setCopying(false);
    setProgress(100);
  }
}

export function* doubleCampaignsWorker({
  payload,
}: ReturnType<typeof doubleCampaigns>): SagaIterator<void> {
  const { to, from, partner_xxhash, callback } = payload;
  try {
    const result: TRCloneCampaigns = yield call(campaignClone, {
      campaign_xxhash_list: from.map((item) => item.xxhash),
      client_xxhash_list: to.map((item) => item.xxhash),
    });
    if (result) {
      const status = yield select(selectorCampaignsTabs);
      const { data: settings } = extractGenFetchData<SettingConfig[]>(
        yield select(selectorUserSettings),
      );
      if (callback) {
        callback();
      }
      if (getSettingsValue(settings, 'app_notification')) {
        toastSuccessDoubled(
          from,
          result.map(({ xxhash_new_campaign }) => xxhash_new_campaign),
          true,
        );
      }
      if (partner_xxhash) {
        yield put(
          fetchCampaigns({
            partner_xxhash: partner_xxhash as string,
            filters: {
              status,
            },
          }),
        );
      } else {
        yield put(
          fetchCampaigns({
            partner_xxhash: to[0].xxhash as string,
            filters: {
              status,
            },
          }),
        );
      }
    }
  } catch (e) {
    console.error(e);
  }
}

export function* exportItemsToExcelWorker({
  payload,
}: ReturnType<typeof exportItemsToExcel>): SagaIterator<void> {
  try {
    const href =
      payload.key === 'geo_id'
        ? yield call(geoExportItemsToCSV)
        : yield call(targetingExportItemsToExcel, payload);
    if (href) {
      const link = document.createElement('a');
      link.href = href;
      link.rel = 'noopener noreferrer';
      link.click();
    }
  } catch (e) {
    console.error(e);
  }
}

export function* fetchVendorLinkDataSagaWorker(): SagaIterator<void> {
  const campaignsIcons = yield select(selectorCampaignsIcons);
  if (campaignsIcons?.length) return;
  try {
    yield call<TMakeReqWithRD<typeof getIconsList>>(makeReqWithRD, {
      fetcher: getIconsList,
      fill: (data) => setCampaignsIcons(data.get('data') || []),
    });
  } catch (e) {
    console.log({ e });
  }
}

export function* workerSetCampaignsIcon({
  payload,
}: ReturnType<typeof setCampaignsIcon>): SagaIterator<void> {
  try {
    yield call(editPersonalCampaign, payload);
    const tempCampaigns: ReturnType<typeof selectorCampaignsView> =
      yield select(selectorCampaignsView);
    const campaigns = tempCampaigns.get('listView');
    if (campaigns === null) return;
    const index = campaigns?.findIndex((c) => c.xxhash === payload.xxhash);
    const campaign = { ...campaigns[index] };
    if (!campaign) return;
    campaign.icon = payload.icon;
    campaigns[index] = { ...campaign };
    const list = makeGenList(campaigns);
    setStoragesIcons(payload.icon);
    yield put(setCampaignsListView(list));
  } catch (e) {
    console.log({ e });
  }
}

export function* workerClearCampaignsIcon({
  payload,
}: ReturnType<typeof clearCampaignsIcon>): SagaIterator<void> {
  try {
    yield call(editPersonalCampaign, { xxhash: payload.xxhash, icon: '' });
    const tempCampaigns: ReturnType<typeof selectorCampaignsView> =
      yield select(selectorCampaignsView);
    const campaigns = tempCampaigns.get('listView');
    if (campaigns === null) return;
    const index = campaigns?.findIndex((c) => c.xxhash === payload.xxhash);
    const campaign = { ...campaigns[index] };
    if (!campaign) return;
    campaign.icon = null;
    campaigns[index] = { ...campaign };
    const list = makeGenList(campaigns);
    yield put(setCampaignsListView(list));
  } catch (e) {
    console.log({ e });
  }
}

export function* fetcherPartnerStatisticFieldListWorker({
  payload,
}: ReturnType<typeof fetchStatisticFieldList>): SagaIterator<void> {
  const savedData: ReturnType<typeof selectorPartnerStatisticFieldList> =
    yield select(selectorPartnerStatisticFieldList);
  try {
    yield call<TMakeReqWithRD<typeof partnerGetStatisticFieldList>>(
      makeReqWithRD,
      {
        fetcher: partnerGetStatisticFieldList,
        fill: (value) => {
          const result = value.get('data');
          const newResult: TStatisticsFields | null =
            result && result.sort((a, b) => (a.order > b.order ? 1 : -1));
          return setStatisticFieldList(
            updateFillFetchedData<TStatisticsFields, TStatisticsFields>({
              fillFetchedData: value,
              newData: newResult || [],
            }),
          );
        },
        parameters: payload,
        preData: savedData
          .get('data')
          ?.sort((a, b) => (a.order > b.order ? 1 : -1)),
      },
    );
  } catch (e) {
    console.error({ e });
  }
}

export function* setPartnerStatisticFieldsWorker({
  payload,
}: ReturnType<typeof setPartnerStatisticsFields>): SagaIterator<void> {
  try {
    const { xxhash, field_list, types } = payload;
    const result = yield call(setPartnerStatisticsApi, { xxhash, field_list });
    if (result) {
      toastSuccess({
        before: `${i18next.t(
          'campaigns_page.campaign_settings.on_edit.hint1',
        )}`,
        title: ` "${i18next.t(
          'campaigns_page.campaign_settings.additional.statistics.title',
        )}" `,
        after: `${i18next.t('campaigns_page.campaign_settings.on_edit.hint2')}`,
      });

      yield put(fetchStatisticFieldList({ xxhash, types }));
    }
  } catch (e) {
    console.error({ e });
  }
}

export function* fetchTotalListStatWorker({
  payload,
}: ReturnType<typeof fetchCampaignsTotal>): SagaIterator<void> {
  const { debounce, ...parameters } = payload;

  try {
    // debounce effect
    if (debounce) {
      yield delay(debounce);
    }
    yield call<TMakeReqWithRD<typeof getTotalListStat>>(makeReqWithRD, {
      fetcher: getTotalListStat,
      fill: setTotal,
      parameters,
    });
  } catch (e) {
    console.error({ e });
  }
}
