import React, { FC, useEffect, useCallback, ReactNode } from 'react';
import cn from 'classnames';
import { useSpring, animated } from 'react-spring';
import { createPortal } from 'react-dom';
import css from './ModalOverlay.module.scss';

type TIsWide = {
  isWide?: boolean;
};

const modalRoot: Element = document.getElementById('modal-portal') as Element;
type TPModalAnim = TIsWide & { children: ReactNode };

const ModalAnim: FC<TPModalAnim> = ({ children, isWide }) => {
  useEffect(() => {
    document.body.style.overflow = 'hidden';
    document.body.style.paddingRight = '8px';
    return () => {
      document.body.removeAttribute('style');
    };
  }, []);
  const cardProps = useSpring({
    to: {
      transform: `translateY(0)`,
    },
    from: { transform: 'translateY(-50px)' },
    config: {
      mass: 3,
      testion: 350,
      friction: 40,
      duration: 200,
    },
  });
  return (
    <div className={cn(css.content, 'ModalOverlay_content')}>
      <animated.div className={css.bg} />
      <animated.div
        className={cn(css.dialog, 'ModalOverlay_dialog', { isWide })}
        style={cardProps}
      >
        {children}
      </animated.div>
    </div>
  );
};

type IModalOverlay = TIsWide & {
  /** Показывает или скрывает модалку.По умолчанию скрыто */
  isOpen?: boolean;
  /** Вызывается при клике на фон  */
  onCloseModal: () => void;
  /** ReactNode  */
  children: ReactNode;
  /** Пользовательский css класс  */
  className?: string;
};

/**
 * Компонент обертка для модального окна
 */
const ModalOverlay: FC<IModalOverlay> = ({
  isOpen = false,
  children,
  onCloseModal,
  className,
  isWide,
}) => {
  const onClose = useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      const el = e.target;
      let id = '';
      if (el instanceof Element) {
        id = el.id;
      }

      if (id !== 'modal_cover' || e.detail === 1) return false;
      onCloseModal();
      return null;
    },
    [],
  );

  if (isOpen === false) return null;

  return createPortal(
    <div
      className={cn(css.modal, className, 'ModalOverlay')}
      onClick={onClose}
      id="modal_cover"
    >
      <ModalAnim isWide={isWide}>{children}</ModalAnim>
    </div>,
    modalRoot,
  );
};
export default ModalOverlay;
