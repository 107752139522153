import { SagaIterator } from 'redux-saga';
import { call, put, select } from 'redux-saga/effects';
import i18next from 'i18next';
import moment from 'moment';
import {
  getPersonalInfo,
  editUserPersonal,
  setPushTokenNotification,
  getDcoUrl,
  userAuth,
  userSingUp,
  userConfirm,
  userForGot,
  userForGotChangePassword,
  userRefreshToken,
  getUserConfigs,
  setUserConfigs,
  getInfoByHesh,
  getConfirmCreate,
  editPassword,
  getActiveAccounts,
  changeAccountBySession,
  removeSession,
  removeCurrentAccountBySession,
  removeOneAccount,
} from '../api';
import {
  setPersonalInfo,
  setEditPersonal,
  sentPushNotificationRequest,
  singInRequest,
  setTempSingAuth,
  singUpRequest,
  setAuthResponse,
  setConfirmRequest,
  forGotPasswordRequest,
  forGotChangeRequest,
  setHideNavigation,
  setUnblockSanfor,
  openUrlByToken,
  setUserInfoSuccess,
  setTempSingAuthSuccess,
  setUserInfoFinally,
  setSettingsUser,
  setInfoByHash,
  editPasswordAction,
  setActiveAccounts,
} from '../reduser';
import {
  makeReqWithRD,
  TMakeReqWithRD,
  updateFillFetchedData,
} from '../../../redux/makeReqWithRD';
import updateToken from '../../../utils/updateToken';
import {
  fetchUserInfo,
  fetchConfirmCreate,
  fetchPersonalInfo,
  fetchActiveAccounts,
  fetchChangeAccountBySession,
  fetchRemoveSession,
  fetchRemoveCurrentAccountBySession,
  fetchRemoveOneAccount,
} from '../actions';
import { toastSuccess } from '../../../utils/ToastUtils/toastUtils';
import i18n from '../../../i18n';
import { setLogoInTheProject } from '../../../utils/logoBrandWithPrioject';
import { genFetchedData } from '../../../redux/fetchedData';
import { RAuthToken, ResponseUser, SettingConfig, TGetActiveAccounts, UserConfig } from '../types';
import { getToken, setToken } from '../../../lib/helpers/token';
import history from '../../../lib/history';
import { wssTokenRefresh } from '../../wss/model/actions';
import { selectorUser } from '../model/selectors';
import { userNotificationPush } from '../model/hooks';

function* accountSessionWorker(
  payload: any,
  actionType: 'remove' | 'change' | 'auth',
): Generator<any, void, any> {
  let fetchedData = genFetchedData<RAuthToken>(null).set('isLoading', true);
  yield put(setTempSingAuth(fetchedData));
  const from = JSON.parse(sessionStorage.getItem('$from') || 'null');

  try {
    let result;

    if (actionType === 'remove') {
      result = yield call(removeCurrentAccountBySession, payload);
    } else if (actionType === 'change') {
      result = yield call(changeAccountBySession, payload);
    } else if (actionType === 'auth') {
      result = yield call(userAuth, payload);
    }

    setToken(result);
    fetchedData = fetchedData.set('data', result);

    if (from) {
      window.location.replace(`${from.pathname}${from.search}`);
      sessionStorage.removeItem('$from');
      return;
    }

    yield put(setTempSingAuthSuccess(fetchedData));
    window.location.replace('/');
  } catch (err) {
    fetchedData = fetchedData.set('error', {
      isError: true,
      message: err.data.mnemonic,
      code: err?.code || 0,
    });
  } finally {
    fetchedData = fetchedData.delete('isLoading').set('LTU', Date.now());
    yield put(setTempSingAuth(fetchedData));
  }
}

export function* workerFetchPersonalInfo(): SagaIterator<void> {
  try {
    yield call(fetchUserSettings);
    yield call<TMakeReqWithRD<typeof getPersonalInfo>>(makeReqWithRD, {
      fetcher: getPersonalInfo,
      fill: setPersonalInfo,
      fillSuccess: setUserInfoSuccess,
      fillFinally: setUserInfoFinally,
    });

    const userTemp = yield select(selectorUser);
    const user = userTemp.get('data');
    i18n.changeLanguage(user.language);
    setLogoInTheProject(user.partner.xxhash, user.partner.icon);

    // ‼‼ Костыль для одного пользователя https://sniper.atlassian.net/browse/AS-1637
    const pathnameForSanfor =
      '/main/campaign/FE7199BBD831F363/general-reports/general';
    const searchForSanfor = `?date_from=2020-07-01&date_to=${moment().format(
      'YYYY-MM-DD',
    )}&type=integration`;

    if (user.email === 'td@sanfor.ru') {
      yield put(setHideNavigation(true));
      if (history.location.pathname !== pathnameForSanfor) {
        history.push(pathnameForSanfor + searchForSanfor);
      }
      const unblockSanfor = history.block((arg) => {
        if (arg.pathname === pathnameForSanfor) {
          return;
        }
        if (['/', '/auth/sign-in', '/main', '/auth'].includes(arg.pathname)) {
          return;
        }

        // eslint-disable-next-line consistent-return
        return false;
      });
      yield put(setUnblockSanfor(unblockSanfor));
    } else {
      const unblockSanfor = yield select(
        ({ userReducer }) => userReducer.unblockSanfor,
      );
      if (unblockSanfor !== null) {
        unblockSanfor();
      }
    }
  } catch (e) {
    console.error({ e });
  }
}

export function* editUserPersonalSagaWorker({
  payload,
}: ReturnType<typeof setEditPersonal>): SagaIterator<void> {
  try {
    const { user, design } = payload;
    if (
      payload &&
      (!!Object.keys(user).length || !!Object.keys(design).length)
    ) {
      let result = false;
      yield call(updateToken);
      if (Object.keys(user).length) {
        result = yield call(editUserPersonal, user);
      }
      if (Object.keys(design).length) {
        const arr = Object.keys(design);
        // eslint-disable-next-line
        while (0 < arr.length) {
          const current = arr.shift();
          if (current) {
            result = yield call(setUserConfigs, {
              field_id: +current,
              value: design[current],
            });
          }
        }
      }

      if (result) {
        if (Object.keys(user).length) {
          const userState = yield select(selectorUser)
          const userData = userState.get('data')
          const newData = {...userData, ...user}
          const fetchedData = genFetchedData<ResponseUser>(null).set('data', newData);
          yield put(setPersonalInfo(fetchedData))
          if (user.lang) {
            localStorage.setItem('lang', user.lang);
            i18n.changeLanguage(user.lang);
          }
        }
        if (Object.keys(design).length) {
          const settingsState = yield select(({userReducer}) => userReducer.settings)
          const settingsData: SettingConfig[] = settingsState.get('data')
          const currentKey = Object.keys(design)[0]
          const dataCopy = settingsData.filter(item => item.id !== +currentKey)
          const currentItem = settingsData.find(item => item.id === +currentKey)
          if (currentItem) {
            const settingsObjCopy = {...currentItem}
            settingsObjCopy.value = design[currentKey]
            const fetchedData = genFetchedData<SettingConfig[]>(null).set('data', [...dataCopy, settingsObjCopy]);
            yield put(setSettingsUser(fetchedData))
          }
        }
        toastSuccess(i18next.t('profile.edit.edit_save'));
      }
    }
  } catch (e) {
    yield put(fetchPersonalInfo());
    console.error({ e });
  }
}

export function* sentPushNoticationSagaWorker({
  payload,
}: ReturnType<typeof sentPushNotificationRequest>): SagaIterator<void> {
  try {
    yield call(setPushTokenNotification, payload);
  } catch (e) {
    console.error({ e });
  }
}

export function* DCOUrlWorker(): SagaIterator<void> {
  const result = yield call(getDcoUrl);
  if (result) {
    const link = document.createElement('a');
    link.href = result;
    link.target = '_blank';
    link.rel = 'noopener noreferrer';
    link.click();
  }
}

export function* singInWorker({
  payload,
}: ReturnType<typeof singInRequest>): SagaIterator<void> {
  yield* accountSessionWorker(payload, 'auth');
}

export function* singUpWorker({
  payload,
}: ReturnType<typeof singUpRequest>): SagaIterator<void> {
  let fetchedData = genFetchedData<boolean>(null).set('isLoading', true);
  yield put(setAuthResponse(fetchedData));
  try {
    const result = yield call(userSingUp, payload);
    if (result) {
      fetchedData = fetchedData.set('data', result);
      yield put(setAuthResponse(fetchedData));
      history.push({
        pathname: '/auth/sign-in',
        state: {
          registration: true,
        },
      });
    }
  } catch (err) {
    fetchedData = fetchedData.set('error', {
      isError: true,
      message: err.data.mnemonic,
      code: err?.code || 0,
    });
  } finally {
    fetchedData = fetchedData.delete('isLoading').set('LTU', Date.now());
    yield put(setAuthResponse(fetchedData));
  }
}

export function* confirmWorker({
  payload,
}: ReturnType<typeof setConfirmRequest>): SagaIterator<void> {
  let fetchedData = genFetchedData<boolean>(null).set('isLoading', true);
  yield put(setAuthResponse(fetchedData));
  try {
    const result = yield call(userConfirm, payload);

    fetchedData = fetchedData.set('data', result);
  } catch (err) {
    fetchedData = fetchedData.set('error', {
      isError: true,
      message: err.data.mnemonic,
      code: err?.code || 0,
    });
  } finally {
    fetchedData = fetchedData.delete('isLoading').set('LTU', Date.now());
    yield put(setAuthResponse(fetchedData));
    localStorage.removeItem('$user_update');
  }
}

export function* forgotPasswordWorker({
  payload,
}: ReturnType<typeof forGotPasswordRequest>): SagaIterator<void> {
  let fetchedData = genFetchedData<boolean>(null).set('isLoading', true);
  yield put(setAuthResponse(fetchedData));
  try {
    const result = yield call(userForGot, payload);

    fetchedData = fetchedData.set('data', result);
    if (result) {
      history.push({
        pathname: '/auth/sign-in',
        state: {
          isForgotConfirm: true,
        },
      });
    }
  } catch (err) {
    fetchedData = fetchedData.set('error', {
      isError: true,
      message: err.data.mnemonic,
      code: err?.code || 0,
    });
  } finally {
    fetchedData = fetchedData.delete('isLoading').set('LTU', Date.now());
    yield put(setAuthResponse(fetchedData));
  }
}

export function* changePasswordWorker({
  payload,
}: ReturnType<typeof forGotChangeRequest>): SagaIterator<void> {
  let fetchedData = genFetchedData<boolean>(null).set('isLoading', true);
  yield put(setAuthResponse(fetchedData));
  try {
    const result = yield call(userForGotChangePassword, payload);

    fetchedData = fetchedData.set('data', result);
    if (result) {
      localStorage.removeItem('$user_update');
      history.push({
        pathname: '/auth/sign-in',
        state: {
          passChange: true,
        },
      });
    }
  } catch (err) {
    fetchedData = fetchedData.set('error', {
      isError: true,
      message: err.data.mnemonic,
      code: err?.code || 0,
    });
  } finally {
    fetchedData = fetchedData.delete('isLoading').set('LTU', Date.now());
    yield put(setAuthResponse(fetchedData));
  }
}

export function* getTokenWorker(): SagaIterator<void> {
  const token = getToken();
  let fetchedData = genFetchedData<RAuthToken>(null).set('isLoading', true);
  yield put(setTempSingAuth(fetchedData));
  if (token) {
    fetchedData = fetchedData.set('data', token);
    yield put(setTempSingAuth(fetchedData));
  } else {
    // fetchedData = fetchedData.set('error', {
    //   isError: true,
    //   message: 'error token',
    // });
    // yield put(setTempSingAuth(fetchedData));
    const { messaging } = userNotificationPush();
    if (messaging) {
      localStorage.removeItem('$push-token');
      messaging.deleteToken();
    }
  }
  fetchedData = fetchedData.delete('isLoading').set('LTU', Date.now());
  yield put(setTempSingAuth(fetchedData));
}

export function* refreshTokenWorker(): SagaIterator<void> {
  let fetchedData = genFetchedData<RAuthToken>(null).set('isLoading', true);
  yield put(setTempSingAuth(fetchedData));
  try {
    const token = getToken() || { refresh_token: '' };
    const { refresh_token } = token;
    const result = yield call(userRefreshToken, { token: refresh_token });
    setToken(result);
    fetchedData = fetchedData.set('data', result);
  } catch (err) {
    fetchedData = fetchedData.set('error', {
      isError: true,
      message: err.data.mnemonic,
      code: err?.code || 0,
    });
  } finally {
    fetchedData = fetchedData.delete('isLoading').set('LTU', Date.now());
    yield put(setTempSingAuth(fetchedData));
    yield put(wssTokenRefresh());
  }
}

export function* openUrlByTokenWorker({
  payload,
}: ReturnType<typeof openUrlByToken>): SagaIterator<void> {
  try {
    yield call(updateToken);

    const tokensJSON = localStorage.getItem('$token');
    if (tokensJSON === null) return;
    const accessToken: string | undefined =
      JSON.parse(tokensJSON)?.access_token;
    if (accessToken === undefined) return;
    const url = `${payload}${accessToken}`;
    window.open(url, '_blank');
  } catch (err) {
    console.error(err);
  }
}

function* fetchUserSettings(): SagaIterator<void> {
  try {
    yield call<TMakeReqWithRD<typeof getUserConfigs>>(makeReqWithRD, {
      fetcher: getUserConfigs,
      fill: (v) => {
        const data = v.get('data');
        const newData: SettingConfig[] = data
          ? Object.entries(data).map(([, value]) => value)
          : [];
        return setSettingsUser(
          updateFillFetchedData<UserConfig, SettingConfig[]>({
            fillFetchedData: v,
            newData,
          }),
        );
      },
    });
  } catch (e) {
    console.error(e);
  }
}

export function* fetchUserInfoWorker({
  payload,
}: ReturnType<typeof fetchUserInfo>): SagaIterator<void> {
  try {
    yield call<TMakeReqWithRD<typeof getInfoByHesh>>(makeReqWithRD, {
      fetcher: getInfoByHesh,
      fill: setInfoByHash,
      parameters: payload,
    });
  } catch (e) {
    console.error(e);
  }
}

export function* userCreateWorker({
  payload,
}: ReturnType<typeof fetchConfirmCreate>): SagaIterator<void> {
  try {
    yield call(updateToken);
    let result: boolean | null = null;
    result = yield call(getConfirmCreate, payload);

    if (result) {
      localStorage.removeItem('$user_update');
      history.push({
        pathname: '/auth/sign-in',
        state: {
          registration: true,
        },
      });
    }
  } catch (e) {
    console.error({ e });
  }
}

export function* editPasswordWorker({
  payload,
}: ReturnType<typeof editPasswordAction>): SagaIterator<void> {
  try {
    const {data, callbacks} = payload
    const result = yield call(editPassword, data);
    if (result) {
      toastSuccess(i18next.t('login_page.passChange'));
      callbacks.resetPasswordData()
    }
  } catch (e) {
    console.error(e);
  }
}

export function* getActiveAccountsWorker({
  payload,
}: ReturnType<typeof fetchActiveAccounts>): SagaIterator<void> {
  const { callback } = payload;
  try {
    callback.setIsLoading(true);
    yield call<TMakeReqWithRD<typeof getActiveAccounts>>(makeReqWithRD, {
      fetcher: getActiveAccounts,
      fill: setActiveAccounts,
      parameters: payload,
    });
  } catch (e) {
    console.error(e);
    callback.setIsLoading(false);
  } finally {
    callback.setIsLoading(false);
  }
}

export function* changeAccountWorker({
  payload,
}: ReturnType<typeof fetchChangeAccountBySession>): SagaIterator<void> {
  yield* accountSessionWorker(payload, 'change');
}

export function* removeSessionWorker({
  payload,
}: ReturnType<typeof fetchRemoveSession>): SagaIterator<void> {
  const { session_token, logOut } = payload;
  try {
    yield call(removeSession, { session_token });
  } catch (e) {
    console.error(e);
  } finally {
    logOut();
  }
}

export function* removeCurrentAccountBySessionWorker({
  payload,
}: ReturnType<typeof fetchRemoveCurrentAccountBySession>): SagaIterator<void> {
  yield* accountSessionWorker(payload, 'remove');
}

export function* removeOneAccountWorker({
  payload,
}: ReturnType<typeof fetchRemoveOneAccount>): SagaIterator<void> {
  const { email, session_token, callback } = payload;
  try {
    yield call(removeOneAccount, { email, session_token });
  } catch (e) {
    console.error(e);
  } finally {
    if (session_token && callback) {
      const getActiveAccountsPayload: TGetActiveAccounts = {
        session_token,
        callback,
      };
      yield call(getActiveAccountsWorker, {
        payload: getActiveAccountsPayload,
        type: '',
      });
    }
  }
}