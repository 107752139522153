import cn from 'classnames';
import React, {
  InputHTMLAttributes,
  RefObject,
  TextareaHTMLAttributes,
  FC,
  useLayoutEffect,
  useRef,
} from 'react';
import { MdInfoOutline } from 'react-icons/md';
import i18next from 'i18next';
import copy from 'copy-to-clipboard';
import input from './styles.module.scss';
import Label, { PLabel } from './Label';
import { FetchedDataObj } from '../../../redux/fetchedData';
import ErrorDictionary from '../../Errors/ErrorDictionary';
import css from '../../../domains/creative/creatives/CreativeEdit/Moderation/ModalInfo/styles.module.scss';
import { THelper } from '../../../domains/creative/model/hooks/useValidateVendorLInk';
import ErrorText from '../ErrorText';
import { toolTipType } from './types';
import { toastSuccess } from '../../../utils/ToastUtils/toastUtils';

type PInput = {
  /** Полный объект ошибки */
  errorData?: FetchedDataObj<null>['error'];
  /** подкрашивает как ошибку  =false */
  error?: boolean;
  /** Текст лейбла =undefined */
  label?: string;
  /** открывает ссылку */
  isLink?: boolean;
  /** текст ошибки =undefined */
  errortext?: string;
  /** показывать количество введенных символов */
  showValueCount?: boolean;
  /** текст количеству символов =creative.create_creative.symbols */
  textValueCount?: string;
  /** устанавливает disabled в инпут применяется к стилям =false */
  disabled?: boolean;
  /** Скрывает поле инпута */
  notField?: boolean;
  /** Свои стили для textarea */
  classname?: string;
  /** атрибуты инпута =undefined */
  inputAttributes?:
    | InputHTMLAttributes<HTMLInputElement>
    | TextareaHTMLAttributes<HTMLTextAreaElement>;
  /** Использование Атрибута Ref */
  innerRef?:
    | RefObject<HTMLInputElement>
    | RefObject<HTMLTextAreaElement>
    | null
    | undefined;
  /** использовать компонент textarea */
  isTextarea?: boolean;
  /** иконка в инпуте */
  icon?: JSX.Element;
  /** Класс для иконки в инпуте */
  icon_classname?: string;
  /** отображать кнопку копировать */
  isCopy?: boolean;
  /** Вывод ошибок */
  helper?: THelper;
  /** Вывод тултипа со ссылкой */
  tooltip?: toolTipType;
  /** Горизонтальный стиль */
  theme?: string;
  mode?: string;
  onCustomChange?: (value: React.ChangeEvent<HTMLInputElement>) => void;
};
export interface IinputValue {
  value?: string | null;
}
export type TAllProps = PInput & Partial<PLabel>;
const Input: FC<TAllProps & IinputValue> = ({
  errorData,
  error = false,
  disabled = false,
  label,
  errortext,
  notField = true,
  classname,
  showValueCount = false,
  textValueCount = i18next.t('creative.create_creative.symbols'),
  inputAttributes,
  innerRef,
  isLink,
  isTextarea = false,
  icon,
  icon_classname,
  helper,
  isCopy = false,
  tooltip,
  theme,
  value,
  onCustomChange,
  mode,
}) => {
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const MIN_TEXTAREA_HEIGHT = 32;

  useLayoutEffect(() => {
    if (textareaRef && textareaRef.current !== null) {
      textareaRef.current.style.height = 'inherit';
      textareaRef.current.style.height = `${Math.max(
        textareaRef.current.scrollHeight,
        MIN_TEXTAREA_HEIGHT,
      ) + 2}px`;
    }
  }, [inputAttributes?.value, notField]);

  return (
    <div
      className={cn(
        'input-form-group',
        input['form-group'],
        classname,
        error || (errorData && errorData.isError)
          ? input['form-group_error']
          : '',
        {
          horizontal: theme === 'horizontal',
          noLabel: !label,
          disabled: disabled || inputAttributes?.disabled,
        },
      )}
    >
      {label && (
        <Label
          label={label}
          forInput={inputAttributes?.id || inputAttributes?.name}
          tooltip={tooltip}
        />
      )}
      {showValueCount && notField && (
        <div className={input.maxCount}>
          {`${inputAttributes?.value?.toString().length ?? 0}/${
            inputAttributes?.maxLength ?? ''
          }${textValueCount}`}
        </div>
      )}
      {!notField && isLink && (
        <div className={`${input.title} ${Date.now()}${Math.random()}`}>
          {inputAttributes && inputAttributes.value && (
            <div
              className={input.link}
              onClick={() => {
                const link = document.createElement('a');
                link.download = 'file';
                link.href =
                  `${inputAttributes.value}`.indexOf('http') + 1
                    ? `${inputAttributes.value}`
                    : `http://${inputAttributes.value}`;
                link.target = '_blank';
                link.rel = 'noopener noreferrer';
                link.click();
              }}
            >
              {inputAttributes.value}
            </div>
          )}
        </div>
      )}
      {!isLink && !notField && (
        <div className={input.title}>
          {inputAttributes?.value === '' && mode !== 'JScode'
            ? '-'
            : inputAttributes?.value}
        </div>
      )}
      {notField && (
        <>
          {isTextarea && (
            <textarea
              className={classname}
              ref={textareaRef}
              disabled={disabled}
              rows={1}
              id={inputAttributes?.id || inputAttributes?.name}
              {...(inputAttributes as TextareaHTMLAttributes<HTMLTextAreaElement>)}
            />
          )}
          {!isTextarea && (
            <div className={input.body}>
              <input
                className={classname}
                ref={innerRef as RefObject<HTMLInputElement>}
                value={value || ''}
                disabled={disabled}
                onChange={(e) => onCustomChange && onCustomChange(e)}
                id={inputAttributes?.id || inputAttributes?.name}
                {...(inputAttributes as InputHTMLAttributes<HTMLInputElement>)}
              />
              <div className={cn(input.icon, icon_classname)}>{icon}</div>
            </div>
          )}
        </>
      )}
      {error &&
        errortext &&
        (errorData === undefined || (errorData && !errorData.isError)) && (
          <ErrorText text={errortext} />
        )}
      {errorData && errorData.isError && (
        <ErrorText text={<ErrorDictionary code={errorData.code} />} />
      )}
      {!error && helper && helper.helperText && (
        <span
          className={
            input[
              helper.status === 'success'
                ? 'form-group__errorSuccess'
                : 'form-group__errorText'
            ]
          }
        >
          <MdInfoOutline size="20" />
          {helper.helperText}
        </span>
      )}
      {isCopy && (
        <div
          className={css.copy}
          onClick={() => {
            copy(`${inputAttributes?.value}`);
            toastSuccess(`${label} ${i18next.t(`alerts.a3`)}`);
          }}
        >
          <span>{i18next.t(`creative.moderation.modal.labelBtn`)}</span>
        </div>
      )}
    </div>
  );
};

export default Input;
