import React, { FC, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { FiChevronDown } from 'react-icons/fi';
import userPng from 'assets/ava.webp';
import { useUserInfo } from '../../domains/user/model/selectors';
import css from './styles.module.scss';
import { useHeaderAccount } from './hook/useHeaderAccount';
import Front from './components/Front';
import ChangeAccount from './components/ChangeAccount';
import LogIn from './components/LogIn';

const HeaderAccount: FC = () => {
  const { data: user } = useUserInfo();
  const { t } = useTranslation();
  const profileRef = useRef<HTMLDivElement>(null);
  const menuRef = useRef<HTMLDivElement>(null);
  const { open, setOpen, goToProfile, page, setPage, logOut } =
    useHeaderAccount({
      bodyRef: profileRef,
      dropdownMenuRef: menuRef,
    });

  return (
    <div className={cn(css.profile_menu, { _isOpened: open })} ref={profileRef}>
      <div className={css.profile_menu__view} onClick={() => setOpen(!open)}>
        <div
          className={cn(css.profile_menu__icon, {
            isDefault: user && user.partner.icon,
          })}
          style={{
            backgroundImage: `url(${
              user && user.partner.icon ? user && user.partner.icon : userPng
            })`,
          }}
        />

        <div className={css.profile_menu__text}>
          <div className={css.profile_menu__login}>
            <span>{user && user.email}</span>
            <FiChevronDown
              size={18}
              color="#fff"
              className={css.profile_menu__chevron}
            />
          </div>
          <div className={css.profile_menu__balance}>
            {window.location.hostname !== 'my.turbotargeting.io' && (
              <span>
                {user && user.partner.type === 'ORGANIZATION'
                  ? t('top.partner_organization')
                  : t('top.partner_managed')}
              </span>
            )}
          </div>
        </div>
      </div>
      <div className={css.profile_menu__dropdown} ref={menuRef}>
        {page === 'FRONT' && (
          <Front
            goToProfile={goToProfile}
            changeAccount={() => setPage('CHANGE')}
            logOut={logOut}
          />
        )}
        {page === 'CHANGE' && (
          <ChangeAccount
            user={user}
            backToFront={() => setPage('FRONT')}
            openLoginPage={() => setPage('LOGIN')}
            logOut={logOut}
          />
        )}
        {page === 'LOGIN' && <LogIn backToChange={() => setPage('CHANGE')} />}
      </div>
    </div>
  );
};

export default HeaderAccount;
