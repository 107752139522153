/* eslint-disable no-nested-ternary */
import { SagaIterator } from '@redux-saga/core';
import { call, put } from 'redux-saga/effects';
import { getTemplateList } from 'domains/creative/api';
import { IGetTemplateListData } from 'domains/creative/types';
import { genFetchedData } from '../../../redux/fetchedData';
import uploadImage, {
  isTUploadErr,
  TResult,
  TUploadErr,
  TUploadFile,
} from '../../../utils/upload';
import updateToken from '../../../utils/updateToken';
import {
  setBusiness,
  setTempFile,
  setTempFileZip,
  setTempHTML,
} from '../reducer';
import { TRFileZip } from '../types';
import {
  fetchBusinessSphere,
  fetchImageRequest,
  fetchZipRequest,
} from '../model/actions';
import {
  getBusinessSphere,
  getUploadFileUrl,
  buildTemplate,
  getDataFileUrl,
} from '../model/api';
import { makeReqWithRD, TMakeReqWithRD } from '../../../redux/makeReqWithRD';

export function* setFileSagaWorker({
  payload,
}: ReturnType<typeof fetchImageRequest>): SagaIterator<void> {
  const {
    typeSection,
    file: currentFile,
    key,
    fileLimits: { maxSizeFile, minResolution, maxResolution },
    videoType,
    creative_type
  } = payload;
  let fetchedData = genFetchedData<TUploadFile | TUploadFile>(null).set(
    'isLoading',
    true,
  );
  yield put(setTempFile({ key, file: fetchedData }));
  try {
    yield call(updateToken);

    const formData = new FormData();
    if (typeof currentFile !== 'string') {
      if (currentFile.size > maxSizeFile * 1024 * 1024) {
        const er = { code: 200032, message: '' };
        throw er;
      }
    }
    let keyFile = 'file';
    if (typeSection === 'client' || typeSection === 'project') {
      keyFile = 'logo';
    }
    if (typeSection === 'project' && key === 'offer') {
      keyFile = 'offer';
    }
    if (typeSection === 'project' && key === 'offer_en') {
      keyFile = 'offer_en';
    }
    if (key === 'iconMask' || key === 'images.icon') {
      keyFile = 'icon';
    }
    if (key === 'imgMask') {
      keyFile = 'image';
    }
    if (key === 'images.image_1' || key === 'images.image_2') {
      keyFile = key.slice(7);
    }

    formData.set(keyFile, currentFile);

    let data:
    | TResult<Record<string, TUploadFile>>
    | TResult<TUploadFile>
    | TUploadErr;
    let error:
      | {
          code: number;
          message: string;
        }
      | undefined;
    let requestUpload = '';
    let result: any;

    if (videoType === 'XML') {
      result = yield call(getDataFileUrl, { file: currentFile });
      result = result.file;
    } else if (videoType === 'MP4') {
      requestUpload = yield call(getUploadFileUrl, { type: 'templateVideo' });
      data = yield call(uploadImage, requestUpload, formData);
      error = isTUploadErr(data) ? data.error : undefined;
      result = isTUploadErr(data) ? undefined : Object.values(data.result)[0];
    }

    if (!videoType) {
      requestUpload = yield call(getUploadFileUrl, { type: typeSection });
      data = yield call(uploadImage, requestUpload, formData);
      error = isTUploadErr(data) ? data.error : undefined;
      result = isTUploadErr(data) ? undefined : Object.values(data.result)[0];
    }

    if (error !== undefined) {
      throw error;
    }
    
/*     if (!file_ext.some((ext) => ext === result?.imageType)) {
      const er = { code: 20340, message: '' };
      throw er;
    } */

    if (
      result?.imageType === 'png' ||
      result?.imageType === 'jpg' ||
      result?.imageType === 'jpeg' ||
      result?.imageType === 'gif'
    ) {
      if (
        (result?.height ?? 0) < minResolution ||
        (result?.height ?? 0) > maxResolution ||
        (result?.width ?? 0) < minResolution ||
        (result?.width ?? 0) > maxResolution
      ) {
        const er = { code: 2031, message: '' };
        throw er;
      }
    }

    if (result && typeSection === 'templateImage') {
      const creativeTemplateList: IGetTemplateListData[] = yield call(
        getTemplateList,
        { creative_type },
      );

      const currentTemplateId = creativeTemplateList.find(
        (template: IGetTemplateListData) => {
          if (result.type.includes(template.title)) {
            return template;
          }
          return null;
        },
      );

      if (currentTemplateId) {
        if (videoType) {
          const params = {
            template_id: currentTemplateId.id,
            replacement_fields: {
              [currentTemplateId.title]: {
                file_url: currentFile,
              },
            },
          };
          const htmlCode = yield call(buildTemplate, params);
          yield put(setTempHTML(htmlCode));
        } else {
          const params = {
            template_id: currentTemplateId.id,
            replacement_fields: {
              [keyFile]: { ...result },
            },
          };
          const htmlCode = yield call(buildTemplate, params);
          yield put(setTempHTML(htmlCode));
        }
      }
    }

    if (Number(result?.size ?? 0) > maxSizeFile * 1024) {
      const er = { code: 200032, message: '' };
      throw er;
    }

    if (result !== undefined) {
      fetchedData = fetchedData.set('data', result);
    }
  } catch (e) {
    console.error({ e });
    const code = e?.code ?? 0;
    fetchedData = fetchedData.set('error', {
      isError: true,
      message: e.message,
      code,
    });
    yield put(setTempFile({ key, file: fetchedData }));
  } finally {
    fetchedData = fetchedData.set('isLoading', false).set('LTU', Date.now());
    yield put(setTempFile({ key, file: fetchedData }));
  }
}

export function* setFileZipWorker({
  payload,
}: ReturnType<typeof fetchZipRequest>): SagaIterator<void> {
  const {
    file,
    key,
    fileLimits: { maxSizeFile },
    type,
  } = payload;
  let fetchedData = genFetchedData<TRFileZip>(null).set('isLoading', true);
  yield put(setTempFileZip({ key, file: fetchedData }));
  try {
    if (file.size > maxSizeFile * 1024 * 1024) {
      const er = { code: 200032, message: '' };
      throw er;
    }

    const requestUpload = yield call(getUploadFileUrl, { type });

    const formData = new FormData();
    formData.set('zip', file);

    const data: TResult<TRFileZip> | TUploadErr = yield call(
      uploadImage,
      requestUpload,
      formData,
    );
    const error = isTUploadErr(data) ? data.error : undefined;
    const result = isTUploadErr(data) ? undefined : data.result;

    if (error !== undefined) {
      throw error;
    }

    fetchedData = fetchedData.set('data', result as TRFileZip);
  } catch (e) {
    console.error({ e });
    const code = e?.code ?? 0;
    fetchedData = fetchedData.set('error', {
      isError: true,
      message: e.message,
      code,
    });
    yield put(setTempFileZip({ key, file: fetchedData }));
  } finally {
    fetchedData = fetchedData.delete('isLoading').set('LTU', Date.now());
    yield put(setTempFileZip({ key, file: fetchedData }));
  }
}

export function* fetchBusinessSphereWorker({
  payload,
}: ReturnType<typeof fetchBusinessSphere>): SagaIterator<void> {
  try {
    yield call<TMakeReqWithRD<typeof getBusinessSphere>>(makeReqWithRD, {
      fetcher: getBusinessSphere,
      fill: setBusiness,
      parameters: payload,
    });
  } catch (e) {
    console.error(e);
  }
}
