import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  RFrontStatistics,
  RGetPersonal,
  RPreTargeting,
  TReportData,
  ListSRMethods,
  TExperimentalInfoField,
  TOptimizationList,
  TGridCampaignForContractData,
} from './types';
import { genFetchedData } from '../../redux/fetchedData';
import { CampaignState, RAdvancedOptimizationLists } from './model/types';
import { TCommentList } from '../comment/model/types';
import { RExternalModeration } from '../creative/types';
import { ResponseTranslation } from '../campaigns/types';

export const initialState: CampaignState = {
  personalData: genFetchedData<RGetPersonal>(null),
  personalStatistics: genFetchedData<RFrontStatistics>(null),
  preTargeting: genFetchedData<RPreTargeting>(null),
  listSRMethods: genFetchedData<ListSRMethods>(null),
  SRMethod: genFetchedData<number>(null),
  partnerTags: genFetchedData<string[]>(null),
  companyTags: genFetchedData<string[]>(null),
  viewReport: genFetchedData<TReportData>(null),
  defChartItemsGen: ['show'],
  externalModeration: genFetchedData<RExternalModeration>(null),
  advancedOptimizationLists: genFetchedData<RAdvancedOptimizationLists>(null),
  comments: genFetchedData<TCommentList>(null),
  translation: genFetchedData<ResponseTranslation>(null),
  experimental: genFetchedData<TExperimentalInfoField[]>(null),
  optimisationList: genFetchedData<TOptimizationList>(null),
  getCampaignGridData: genFetchedData<TGridCampaignForContractData[]>(null),
};

const campaignSlice = createSlice({
  name: 'campaign',
  initialState,
  reducers: {
    setPersonalData(
      state,
      action: PayloadAction<CampaignState['personalData']>,
    ) {
      state.personalData = action.payload;
    },
    setPersonalDataSuccess(
      state,
      action: PayloadAction<CampaignState['personalData']>,
    ) {
      state.personalData = action.payload;
    },
    delPersonalData(state) {
      state.personalData = initialState.personalData;
    },
    setPersonalStatistics(
      state,
      action: PayloadAction<CampaignState['personalStatistics']>,
    ) {
      state.personalStatistics = action.payload;
    },
    setPreTargeting(
      state,
      action: PayloadAction<CampaignState['preTargeting']>,
    ) {
      state.preTargeting = action.payload;
    },
    setListSRMethods(
      state,
      action: PayloadAction<CampaignState['listSRMethods']>,
    ) {
      state.listSRMethods = action.payload;
    },
    setSRMethod(state, action: PayloadAction<CampaignState['SRMethod']>) {
      state.SRMethod = action.payload;
    },
    setPartnerTags(state, action: PayloadAction<CampaignState['partnerTags']>) {
      state.partnerTags = action.payload;
    },
    setCompanyTags(state, action: PayloadAction<CampaignState['companyTags']>) {
      state.companyTags = action.payload;
    },
    resetPartnerTags(state) {
      state.partnerTags = initialState.partnerTags;
    },
    resetCompanyTags(state) {
      state.companyTags = initialState.companyTags;
    },
    setTempViewReport(
      state,
      action: PayloadAction<CampaignState['viewReport']>,
    ) {
      state.viewReport = action.payload;
    },
    setDefChartItemsGenDefault(
      state,
      action: PayloadAction<CampaignState['defChartItemsGen']>,
    ) {
      state.defChartItemsGen = action.payload;
    },
    setDefChartItemsGenAdd(state, action: PayloadAction<string>) {
      state.defChartItemsGen.push(action.payload);
    },
    resetDefChartItemsGen(state) {
      state.defChartItemsGen = initialState.defChartItemsGen;
    },
    setExternalModeration(
      state,
      action: PayloadAction<CampaignState['externalModeration']>,
    ) {
      state.externalModeration = action.payload;
    },
    resetExternalModeration(state) {
      state.externalModeration = initialState.externalModeration;
    },
    setAdvancedOptimizationLists(
      state,
      action: PayloadAction<CampaignState['advancedOptimizationLists']>,
    ) {
      state.advancedOptimizationLists = action.payload;
    },
    setCommentsCampaign(
      state,
      action: PayloadAction<CampaignState['comments']>,
    ) {
      state.comments = action.payload;
    },
    setFetchCampaingTranslation(
      state,
      action: PayloadAction<CampaignState['translation']>,
    ) {
      state.translation = action.payload;
    },
    setFetchCampaingExperimental(
      state,
      action: PayloadAction<CampaignState['experimental']>,
    ) {
      state.experimental = action.payload;
    },
    setOptimisationList(
      state,
      action: PayloadAction<CampaignState['optimisationList']>,
    ) {
      state.optimisationList = action.payload;
    },
    setCampaignGridData(
      state,
      action: PayloadAction<CampaignState['getCampaignGridData']>,
    ) {
      state.getCampaignGridData = action.payload;
    },
  },
});

export const {
  setSRMethod,
  setPreTargeting,
  setPersonalData,
  setPersonalDataSuccess,
  setCompanyTags,
  setListSRMethods,
  setPartnerTags,
  setPersonalStatistics,
  setTempViewReport,
  setDefChartItemsGenAdd,
  setDefChartItemsGenDefault,
  setExternalModeration,
  resetExternalModeration,
  resetCompanyTags,
  resetPartnerTags,
  setAdvancedOptimizationLists,
  setCommentsCampaign,
  setFetchCampaingTranslation,
  setFetchCampaingExperimental,
  setOptimisationList,
  setCampaignGridData,
} = campaignSlice.actions;
export const campaignReducer = campaignSlice.reducer;
