import React, { FC } from 'react';
import TooltipPoratal from 'components/UI/TooltipPoratal';
import { FiUser, FiX } from 'react-icons/fi';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { TActiveAccountsInfo } from 'domains/user/types';
import css from '../../styles.module.scss';

interface IAccountProps {
  item: TActiveAccountsInfo;
  handleChangeAccount: (email: string) => void;
  handleRemoveOneAccount: (email: string) => void;
}

const Account: FC<IAccountProps> = ({
  item,
  handleChangeAccount,
  handleRemoveOneAccount,
}) => {
  const { t } = useTranslation();

  return (
    <div className={css.acc}>
      <div
        className={css.acc_info}
        onClick={() => handleChangeAccount(item.email)}
      >
        <div
          className={cn(css.acc_icon, {
            _isDefault: !(item && item.partner.icon),
          })}
          style={{
            backgroundImage: `url(${
              item.partner.icon ? item.partner.icon : ''
            })`,
          }}
        >
          {!(item && item.partner.icon) && <FiUser size={18} />}
        </div>
        <div className={css.acc_data}>
          <span>{item.email}</span>
          <strong>
            {item.partner.type} {item?.partner.title}
          </strong>
        </div>
      </div>
      <button
        type="button"
        className={css.acc_exit}
        onClick={() => handleRemoveOneAccount(item.email)}
      >
        <TooltipPoratal hint={t(`profile.account.del_and_exit`)}>
          <FiX size={16} />
        </TooltipPoratal>
      </button>
    </div>
  );
};

export default Account;
