import React, { FC, lazy, Suspense, useEffect, useRef } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import {
  Switch,
  Route,
  Redirect,
  RouteComponentProps,
  useParams,
} from 'react-router-dom';
import { toast } from 'react-toastify';
import cn from 'classnames';
import BlackHeader from 'components/BlackHeader';
import ScrollToTopButton from 'components/ScrollToTopButton';
import { useStateValue } from 'utils/context';
import { useTranslation } from 'react-i18next';
import Loader from 'components/UI/Loader';
import ContainersDetail from 'domains/containerDetail';
import { fetchPersonalInfo } from 'domains/user/actions';
import { everyGroups, someGroups } from 'utils/statics/everyGroups';
import {
  NotificationState,
  setBrowserErrorNotification,
} from 'domains/notification/reducer';
import NC from 'domains/app/Errors/NoConnection';
import { useDispatchApp } from 'redux/rootSelectors';
import { userNotificationPush } from 'domains/user/model/hooks';
import { useProjectInfo } from 'domains/project/hooks';
import { checkIsAgencyNotOwn } from 'utils/checkIsAgencyNotOwn';
import { wssCheckBuildAndReloadPage } from 'domains/wss/model/actions';
import css from './styles.module.scss';
import { AppState } from '../../redux/rootReducer';
import { sentPushNotificationRequest } from '../../domains/user/reduser';
import { useIsGroup } from '../../domains/user/hooks';
import i18n from '../../i18n';
import SearchByIdPopup from '../../components/SearchByIdPopup';
import { ResponseUser } from '../../domains/user/types';
import { useUserInfo, useUserTheme } from '../../domains/user/model/selectors';
import { setIsShowModal } from '../../domains/search/model/reducer';

const ProfileNew = lazy(() => import('domains/profile'));
const Campaigns = lazy(() => import('domains/campaigns'));
const CreateCampaign = lazy(() => import('domains/campaigns/CreateCampaign'));
const Containers = lazy(() => import('domains/containers'));
const ClientsList = lazy(() => import('domains/clients'));
const DetailNew = lazy(() => import('domains/campaign'));
const Counteragents = lazy(() => import('domains/counteragents/ui'));
const Counteragent = lazy(() => import('domains/counteragent/ui'));
const Contracts = lazy(() => import('domains/contracts/ui'));
const Contract = lazy(() => import('domains/contract/ui'));
const Offers = lazy(() => import('domains/offers/ui'));
const Offer = lazy(() => import('domains/offer/ui'));
const Client = lazy(() => import('domains/client'));
const Agency = lazy(() => import('domains/agency'));
const Agencies = lazy(() => import('domains/agencies'));
const AgencyReport = lazy(() => import('domains/agency/ui/Reports'));
const NoAccess = lazy(() => import('domains/app/Errors/noAccess'));
const Error404 = lazy(() => import('domains/app/Errors/Error404'));

export const RedirectCustom = (user: ResponseUser | null): string => {
  if (user && user.partner.type === 'ORGANIZATION') {
    return 'agencies';
  }
  if (user && user.partner.type === 'CLIENT') {
    if (
      !someGroups(user, 'default', 'owner', 'self_user') &&
      someGroups(user, 'reports_client')
    ) {
      return `client/${user.partner.xxhash}/reports`;
    }
    return 'campaigns';
  }
  return 'clients';
};

const Main: FC<RouteComponentProps> = ({ match }) => {
  const { data: user, isLoading, LTU, error: userError } = useUserInfo();
  const { data: project } = useProjectInfo();
  const { getValueFromSettingForKey } = useUserTheme();
  const { isOwner, isSelfUser } = useIsGroup();
  const dispatch = useDispatchApp();
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const buildVersionIntervalRef = useRef<any>(null);

  const { isErrorBrowserNotification } = useSelector<
    AppState,
    NotificationState
  >(({ notificationReducer }) => notificationReducer, shallowEqual);
  document.addEventListener('keydown', (event) => {
    if (event.code === 'KeyK' && (event.ctrlKey || event.metaKey)) {
      event.preventDefault();
      dispatch(setIsShowModal(true));
    }
  });

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.hidden) {
        clearInterval(buildVersionIntervalRef.current);
      } else {
        buildVersionIntervalRef.current = setInterval(() => {
          dispatch(wssCheckBuildAndReloadPage());
        }, 5000);
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    handleVisibilityChange();

    return () => {
      clearInterval(buildVersionIntervalRef.current);
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  useEffect(() => {
    const viewModeHide = localStorage.getItem('$viewModeHide');
    const Text = () => (
      <div>
        {t('alerts.view_mode')}
        <br />
        {t('alerts.view_mode_cont')}
      </div>
    );

    if (!viewModeHide) {
      toast(<Text />, {
        type: 'info',
        delay: 500,
        autoClose: 5000,
        onClose: () => {
          localStorage.setItem('$viewModeHide', 'true');
        },
      });
    }

    if (user === null) {
      dispatch(fetchPersonalInfo());
    }

    return () => localStorage.setItem('$viewModeHide', 'true');
  }, []);

  useEffect(() => {
    if (user) {
      const localLang = localStorage.getItem('lang');
      if (localLang !== user.lang) {
        localStorage.setItem('lang', user.lang);
        i18n.changeLanguage(user.lang);
      }
    }
  }, [LTU]);

  const { state } = useStateValue();

  useEffect(() => {
    if (getValueFromSettingForKey('notification')) {
      const { messaging } = userNotificationPush();
      if (messaging) {
        if (Notification.permission === 'denied') {
          dispatch(setBrowserErrorNotification(true));
        }
        if (Notification.permission === 'granted') {
          if (isErrorBrowserNotification) {
            dispatch(setBrowserErrorNotification(false));
          }
        }
        const IntitalizeFireBaseMessaging = () => {
          messaging
            .requestPermission()
            .then(() => messaging.getToken())
            .then((token) => {
              dispatch(sentPushNotificationRequest({ token }));
              localStorage.setItem('$push-token', token);
            })
            .catch((reason) => {
              console.log(reason);
            });
        };
        messaging.onMessage((payload) => {
          const notificationOption = {
            body: payload.notification.body,
            icon: payload.notification.icon,
          };

          if (Notification.permission === 'granted') {
            const notification = new Notification(
              payload.notification.title,
              notificationOption,
            );

            notification.onclick = function (ev) {
              ev.preventDefault();
              window.open(payload.notification.click_action, '_blank');
              notification.close();
            };
          }
        });
        messaging.onTokenRefresh(() => {
          messaging
            .getToken()
            .then((newtoken) => {
              dispatch(sentPushNotificationRequest({ token: newtoken }));
            })
            .catch((reason) => {
              console.log(reason);
            });
        });
        IntitalizeFireBaseMessaging();
      }
    }
  }, [LTU]);

  useEffect(() => {
    if (userError.isError) {
      const { messaging } = userNotificationPush();
      if (messaging) {
        localStorage.removeItem('$push-token');
        messaging.deleteToken();
      }
    }
  }, [LTU]);

  return (
    <div className={cn(css.wrapper, { extend: state.theme.containerToggle })}>
      {isLoading && <Loader />}
      {!isLoading && user && (
        <>
          <BlackHeader />
          <SearchByIdPopup />
          {user.group.length ? (
            <Switch>
              <Route exact path={`${match.path}/profile`}>
                <Suspense fallback={<Loader />}>
                  <ProfileNew />
                </Suspense>
              </Route>
              <Route
                exact
                path={`${match.path}/campaigns`}
                render={(props) => (
                  <Suspense fallback={<Loader />}>
                    {someGroups(user, 'default', 'owner', 'self_user') ? (
                      <Campaigns {...props} />
                    ) : (
                      <NoAccess />
                    )}
                  </Suspense>
                )}
              />
              {(isOwner || isSelfUser) && (
                <Route
                  path={`${match.path}/campaigns/create-campaign`}
                  render={(props) => (
                    <Suspense fallback={<Loader />}>
                      <CreateCampaign {...props} />
                    </Suspense>
                  )}
                />
              )}
              <Route exact path={`${match.path}/containers`}>
                <Suspense fallback={<Loader />}>
                  {everyGroups(user, 'container') ? (
                    <Containers />
                  ) : (
                    <Error404 />
                  )}
                </Suspense>
              </Route>
              {(user.partner.type === 'AGENCY' ||
                user.partner.type === 'ORGANIZATION') && (
                <Route exact path={`${match.path}/clients`}>
                  <Suspense fallback={<Loader />}>
                    <ClientsList />
                  </Suspense>
                </Route>
              )}
              <Route path={`${match.path}/campaign/:id`}>
                <Suspense fallback={<Loader />}>
                  <DetailNew />
                </Suspense>
              </Route>
              <Route
                path={`${match.path}/counteragents`}
                render={(props) => (
                  <Suspense fallback={<Loader />}>
                    {someGroups(user, 'owner', 'self_user') &&
                    (user.partner.type === 'AGENCY' ||
                      user.partner.type === 'ORGANIZATION') &&
                    project?.ord_registration ? (
                      <Counteragents {...props} />
                    ) : (
                      <NoAccess />
                    )}
                  </Suspense>
                )}
              />
              <Route path={`${match.path}/counteragent`}>
                <Suspense fallback={<Loader />}>
                  {someGroups(user, 'owner', 'self_user') &&
                  (user.partner.type === 'AGENCY' ||
                    user.partner.type === 'ORGANIZATION') &&
                  project?.ord_registration ? (
                    <Counteragent />
                  ) : (
                    <NoAccess />
                  )}
                </Suspense>
              </Route>
              <Route
                path={`${match.path}/contracts`}
                render={(props) => (
                  <Suspense fallback={<Loader />}>
                    {someGroups(user, 'owner', 'self_user') &&
                    (user.partner.type === 'AGENCY' ||
                      user.partner.type === 'ORGANIZATION') &&
                    project?.ord_registration ? (
                      <Contracts {...props} />
                    ) : (
                      <NoAccess />
                    )}
                  </Suspense>
                )}
              />
              <Route path={`${match.path}/contract`}>
                <Suspense fallback={<Loader />}>
                  {someGroups(user, 'owner', 'self_user') &&
                  (user.partner.type === 'AGENCY' ||
                    user.partner.type === 'ORGANIZATION') &&
                  project?.ord_registration ? (
                    <Contract />
                  ) : (
                    <NoAccess />
                  )}
                </Suspense>
              </Route>
              <Route
                path={`${match.path}/offers`}
                render={(props) => (
                  <Suspense fallback={<Loader />}>
                    {someGroups(user, 'owner', 'self_user') ? (
                      <Offers {...props} />
                    ) : (
                      <NoAccess />
                    )}
                  </Suspense>
                )}
              />
              <Route path={`${match.path}/offer`}>
                <Suspense fallback={<Loader />}>
                  <Offer />
                </Suspense>
              </Route>
              <Route
                path={`${match.path}/container-detail/:id`}
                render={(props) => (
                  <Suspense fallback={<Loader />}>
                    <ContainersDetail {...props} />
                  </Suspense>
                )}
              />
              <Route path={`${match.path}/client/:id`}>
                <Suspense fallback={<Loader />}>
                  <Client />
                </Suspense>
              </Route>
              <Route path={`${match.path}/agency/:id`}>
                <Suspense fallback={<Loader />}>
                  <Agency />
                </Suspense>
              </Route>
              {user.partner.type === 'ORGANIZATION' && (
                <Route path={`${match.path}/agencies`}>
                  <Suspense fallback={<Loader />}>
                    <Agencies />
                  </Suspense>
                </Route>
              )}
              <Route
                path={`${match.path}/agency/:id/reports`}
                component={
                  everyGroups(user, 'reports_client') &&
                  (user.partner.type === 'ORGANIZATION' ||
                    user.partner.type === 'AGENCY') &&
                  checkIsAgencyNotOwn(user, id)
                    ? AgencyReport
                    : NoAccess
                }
              />
              <Redirect
                exact
                from={match.path}
                to={`${match.path}/${RedirectCustom(user)}`}
              />
              <Route path={`${match.path}/error/nc`} component={NC} />
              <Suspense fallback={<Loader />}>
                <Route component={Error404} />
              </Suspense>
            </Switch>
          ) : (
            <Switch>
              <Route exact path={`${match.path}/profile`}>
                <Suspense fallback={<Loader />}>
                  <ProfileNew />
                </Suspense>
              </Route>
              <Route>
                <Suspense fallback={<Loader />}>
                  <NoAccess />
                </Suspense>
              </Route>
            </Switch>
          )}

          <ScrollToTopButton />
        </>
      )}
    </div>
  );
};

export default Main;
