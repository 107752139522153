import { call } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

export function* diffTimeToken(): SagaIterator<boolean> {
  const token = yield call([localStorage, 'getItem'], '$token');
  if (token && token.expire_access_token) {
    const startTime = new Date(token.expire_access_token).getTime();
    const currentTime = new Date(Date.now()).getTime() / 1000;
    const diff = +currentTime - +startTime;
    return diff >= 0;
  }
  return false;
}
