import React, { FC } from 'react';
import Load from 'components/UI/Load';
import cn from 'classnames';
import css from '../../styles.module.scss';

const LoadingAccount: FC = () => (
  <div className={cn(css.acc, 'isLoadingState')}>
    <div className={css.acc_info}>
      <Load />
      <Load />
    </div>
  </div>
);

export default LoadingAccount;
