import { takeEvery, takeLatest } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';
import {
  fetchTagsgetCompanyWorker,
  fetchTagsPartnerWorker,
  getCampaignPreTargetingWorker,
  getListSRMethodsWorker,
  setCampaignPreTargetingWorker,
  setSRMethodWorker,
  workerEditPersonalCampaign,
  workerFetchPersonalStatistics,
  onDownloadReport,
  workerFetchCampaign,
  fetchReportByTypeWorker,
  getExternalModerationWorker,
  fetchAdvancedOptimizationWorker,
  fetchCampaignCommentsSagaWorker,
  editCampaignCommentSagaWorker,
  addCampaignCommentSagaWorker,
  deleteCampaignCommentSagaWorker,
  delayUpdateCommentSagaWorker,
  setCampaignTagsWorker,
  revertCampaignTagsWorker,
  workerFetchCampaignTranslationWorker,
  fetchCampaignExperimentalWorker,
  setCampaignExperimentalWorker,
  fetchOptimizationListWorker,
  linkCampaignWorker,
  unlinkCampaignWorker,
  workerCreateCampaignGrid,
  workerGridCampaignForContract,
} from './workers';
import {
  fetchAdvancedOptimizationLists,
  fetchPersonalData,
  fetchCampaignPreTargeting,
  fetchDownloadReport,
  fetchExternalModerationRequest,
  fetchListSRMethods,
  fetchPersonalStatistics,
  fetchReportType,
  fetchTagsGetCompany,
  fetchTagsGetPartner,
  setEditPersonalCampaign,
  setPreTargetingRequest,
  setSRMethodRequest,
  fetchCampaignComments,
  editCampaignComment,
  addCampaignComment,
  deleteCampaignComment,
  delayUpdateComment,
  updateTagCampaign,
  revertCampaignTags,
  fetchCampaignTranslation,
  fetchCampaignExperimental,
  setCampaignExperimentalAction,
  fetchOptimizationList,
  linkCampaignToOffer,
  unlinkCampaignToOffer,
  fetchCreateCampaignGrid,
  fetchGridCampaignForContract,
} from '../model/actions';

export default function* watchCampaign(): SagaIterator {
  yield takeEvery(fetchPersonalData, workerFetchCampaign);
  yield takeEvery(fetchPersonalStatistics, workerFetchPersonalStatistics);
  yield takeLatest(setEditPersonalCampaign, workerEditPersonalCampaign);
  yield takeLatest(
    fetchCampaignTranslation,
    workerFetchCampaignTranslationWorker,
  );
  yield takeLatest(fetchCampaignExperimental, fetchCampaignExperimentalWorker);
  yield takeEvery(fetchCampaignPreTargeting, getCampaignPreTargetingWorker);
  yield takeLatest(setPreTargetingRequest, setCampaignPreTargetingWorker);
  yield takeEvery(fetchListSRMethods, getListSRMethodsWorker);
  yield takeEvery(fetchTagsGetCompany, fetchTagsgetCompanyWorker);
  yield takeEvery(fetchTagsGetPartner, fetchTagsPartnerWorker);
  yield takeEvery(setSRMethodRequest, setSRMethodWorker);
  yield takeLatest(fetchDownloadReport, onDownloadReport);
  yield takeLatest(fetchReportType, fetchReportByTypeWorker);
  yield takeEvery(fetchExternalModerationRequest, getExternalModerationWorker);
  yield takeEvery(
    fetchAdvancedOptimizationLists,
    fetchAdvancedOptimizationWorker,
  );
  yield takeEvery(fetchCampaignComments, fetchCampaignCommentsSagaWorker);
  yield takeLatest(addCampaignComment, addCampaignCommentSagaWorker);
  yield takeLatest(editCampaignComment, editCampaignCommentSagaWorker);
  yield takeLatest(deleteCampaignComment, deleteCampaignCommentSagaWorker);
  yield takeEvery(delayUpdateComment, delayUpdateCommentSagaWorker);
  yield takeLatest(updateTagCampaign, setCampaignTagsWorker);
  yield takeLatest(revertCampaignTags, revertCampaignTagsWorker);
  yield takeEvery(setCampaignExperimentalAction, setCampaignExperimentalWorker);
  yield takeEvery(fetchOptimizationList, fetchOptimizationListWorker)
  yield takeLatest(linkCampaignToOffer, linkCampaignWorker);
  yield takeLatest(unlinkCampaignToOffer, unlinkCampaignWorker);
  yield takeEvery(fetchCreateCampaignGrid, workerCreateCampaignGrid);
  yield takeEvery(fetchGridCampaignForContract, workerGridCampaignForContract);
}
