import i18next from 'i18next';
import { ErrorsMap } from 'shared/dictionaryErrors';
import { FetchedDataObj } from '../../redux/fetchedData';

export const getError = (error: FetchedDataObj<null>['error']): string => {
  if (error.code === 219) {
    return i18next.t('login_page.hasEmail');
  }

  if (error.code === 229) {
    return i18next.t('login_page.user_is_al_auth');
  }

  if (error.code === 228) {
    return i18next.t('login_page.sess_is_not_act');
  }

  if (error.code === 90) {
    return i18next.t('login_page.auth_tech_user_not_av');
  }

  const dict: Record<string, string> = {
    UserBlocked: i18next.t('login_page.user_blocked'),
    UserNotActivated: i18next.t('login_page.user_is_not_active'),
    WrongEmailFormat: i18next.t('login_page.error_email'),
    WrongEmailOrPassword: i18next.t('login_page.error_password'),
    RegistrationUnavailable: i18next.t('errors.err203'),
  };

  if (dict[error.message]) {
    return dict[error.message];
  }

  if (error.code && ErrorsMap.has(error.code)) {
    return i18next.t(`${ErrorsMap.get(error.code)}`)
  }

  return i18next.t('login_page.unknown_error');
};
