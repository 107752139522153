import { THASH } from 'types/general';
import { ResponseTranslation } from 'domains/campaigns/types';
import request from '../../lib/helpers/requestApi';
import {
  ParamsEditCampaign,
  PCampaignReport,
  PSetPreTargeting,
  RCampaignReport,
  RGetPersonal,
  RPreTargeting,
  RReportDownload,
  TStatisticsFields,
  PSetStatisticsFields,
  RCampaignReportIntegration,
  TExperimentalInfoField,
  PExperimental,
  TOptimizationList,
  TCreateCampaignGrid,
  TGridCampaignForContractData,
} from './types';
import {
  TAddComment,
  TCommentID,
  TCommentList,
  TEditComment,
} from '../comment/model/types';
import { RAdvancedOptimizationLists } from './model/types';
import { RExternalModeration } from '../creative/types';

export const getCampaignInfo = request<RGetPersonal, THASH>({
  method: 'campaign.getInfo',
});

export const editPersonalCampaign = request<boolean, ParamsEditCampaign>({
  method: 'campaign.edit',
});

export const getPreTargeting = request<RPreTargeting, THASH>({
  method: 'campaign.getPretargeting',
});
export const setPreTargetingApi = request<boolean, PSetPreTargeting>({
  method: 'campaign.setPretargeting',
});
export const getListMethodRecalculatingStatistics = request<string[]>({
  method: 'campaign.getListMethodRecalculatingStatistics',
});

export const tagsGetCampaign = request<string[], THASH>({
  method: 'tags.getCampaign',
});

export const tagsGetPartner = request<string[], THASH>({
  method: 'tags.getCampaign',
});
export const tagsSetInCampaign = request<
  boolean,
  {
    xxhash: string;
    tags: string[];
  }
>({
  method: 'tags.setInCampaign',
  showError: false,
});

/** Получение отчета */
export const getReport = request<
  RCampaignReport & RReportDownload & RCampaignReportIntegration,
  PCampaignReport
>({
  method: 'campaign.getReportById',
});
/** Получение полей статистики */
export const getStatisticsFields = request<TStatisticsFields, THASH>({
  method: 'campaign.getStatisticFieldList',
});
/** Отправка полей статистики */
export const setStatisticsFields = request<boolean, PSetStatisticsFields>({
  method: 'campaign.setStatisticFieldList',
});
/** Получение списка внешней модерации */
export const getExternalModeration = request<RExternalModeration, THASH>({
  method: 'campaign.getExternalModeration',
});

/** Получение пользовательских списков оптимизации */
export const getAdvancedOptimizationLists = request<RAdvancedOptimizationLists>(
  {
    method: 'campaign.getAdvancedOptimizationLists',
  },
);

/** Получение списка комментариев кампании */
export const getCampaignComments = request<TCommentList, THASH>({
  method: 'campaign.getComments',
});

/** Добавление комментария */
export const addCampaignCommentApi = request<boolean, TAddComment>({
  method: 'campaign.addComment',
});

/** Редактирование комментария кампании */
export const editCampaignCommentApi = request<boolean, TEditComment>({
  method: 'campaign.editComment',
});

/** Удаление комментария кампании */
export const removeCampaignCommentApi = request<boolean, TCommentID>({
  method: 'campaign.removeComment',
});
/** Получение данных о трансляции кампании */
export const fetchCampaingTranslationApi = request<ResponseTranslation, THASH>({
  method: 'campaign.checkExport',
});
/** Получение данных о Experimental вкладке */
export const fetchCampaingExperimentalApi = request<
  Record<string, TExperimentalInfoField>,
  {entity_type: "CAMPAIGN"}
>({
  method: 'configField.get',
});

export const setCampaingExperimentalApi = request<boolean, PExperimental>({
  method: 'campaign.setConfigs',
});

export const getOptimizationListApi = request<TOptimizationList>({
  method: 'statisticField.getOptimizationList',
});

export const linkCampaign = request<boolean, { id: number, campaigns_xxhash: string[] }>({
  method: 'offer.linkCampaigns',
});

export const unlinkCampaign = request<boolean, { campaigns_xxhash: string[] }>({
  method: 'offer.unLinkCampaigns',
});

/** Создание кампании в GRID с помощью виджета */
export const createCampaigGrid = request<boolean, Omit<TCreateCampaignGrid, 'callback'>>({
  method: 'campaign.createCampaignToGrid',
});

/** Получение кампаний из GRID */
export const getGridCampaignForContract  = request<Record<string, string>[], TGridCampaignForContractData>({
  method: 'campaign.getGridCampaignForContract',
});


