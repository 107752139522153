import { userNotificationPush } from "domains/user/model/hooks";
import { wssCloseConnect } from "domains/wss/model/actions";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatchApp } from "redux/rootSelectors";
import { useClickOutside } from "utils/useClickOutside";
import { TFPages } from "../types/type";

type TFUseHeaderAccountProps = {
    /** Ref для главного div всего компонента */
    bodyRef: React.RefObject<HTMLDivElement>;
    /** Ref для выпадающего меню */
    dropdownMenuRef: React.RefObject<HTMLDivElement>;
}

type TFUseHeaderAccount = (props: TFUseHeaderAccountProps) => {
    open: boolean;
    setOpen: (value: React.SetStateAction<boolean>) => void;
    goToProfile: () => void;
    page: TFPages;
    setPage: React.Dispatch<React.SetStateAction<TFPages>>
    logOut: () => void,
}

export const useHeaderAccount: TFUseHeaderAccount = ({
    bodyRef,
    dropdownMenuRef
}) => {
    const [lockCloseScroll, setLockCloseScroll] = useState<boolean>(false);
    const [page, setPage] = useState<TFPages>('FRONT');
    const dispatch = useDispatchApp();
    const [open, setOpen] = useState<boolean>(false);
    const history = useHistory();

    useClickOutside({
        targetRef: bodyRef,
        closePanel: () => setOpen(false),
        operationBan: lockCloseScroll
    });

    const logOut = () => {
        const { messaging } = userNotificationPush();
        if (messaging) {
            localStorage.removeItem('$push-token');
            messaging.deleteToken();
        }
        dispatch(wssCloseConnect());
        dispatch({ type: 'AUTH_LOGOUT_SUCCESS' });
        history.push('/');
    };

    const goToProfile = () => {
        history.push('/main/profile/');
        setOpen(false);
    };

    // закрытие модалки, когда скроллим страницу, но пока мы над модалкой, скролл отключаем
    const closeMenuWhenScrolling = () => {
        if (!lockCloseScroll && open) {
            setPage('FRONT')
            setOpen(false)
        }
    }

    // устанавливает запрет на закрытие модалки, если скроллим в самом блоке
    const setLockWhenMouseenter = () => setLockCloseScroll(true);
    const setUnLockWhenMouseenter = () => setLockCloseScroll(false);

    useEffect(() => {
        document.addEventListener('scroll', closeMenuWhenScrolling);
        if (dropdownMenuRef) {
            dropdownMenuRef.current?.addEventListener('mouseenter', setLockWhenMouseenter)
            dropdownMenuRef.current?.addEventListener('mouseleave', setUnLockWhenMouseenter)
        }
        return () => {
            document.removeEventListener('scroll', closeMenuWhenScrolling);
            if (dropdownMenuRef) {
                dropdownMenuRef.current?.removeEventListener('mouseenter', setLockWhenMouseenter)
                dropdownMenuRef.current?.removeEventListener('mouseleave', setUnLockWhenMouseenter)
            }
        };
    }, [open]);

    useEffect(() => {
        if (!open) {
            setPage('FRONT')
        }
    }, [open])

    /** При смене страниц, сбрасывает запрет на скролл вне блока */
    useEffect(() => {
        setLockCloseScroll(false)
    }, [page])

    return {
        open,
        setOpen,
        goToProfile,
        page,
        setPage,
        logOut,
    }
}