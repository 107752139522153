import { createAction } from '@reduxjs/toolkit';
import { PConfirmCreate, PInfoByHash, TFetchRemoveOneAccount, TGetActiveAccounts, TRemoveAllAccounts, TSessionAccountProps } from './types';

export const fetchPersonalInfo = createAction('user/fetchPersonalInfo');

export const fetchUserInfo = createAction<PInfoByHash>('user/fetchUserInfo');

export const fetchConfirmCreate =
  createAction<PConfirmCreate>('user/confirmCreate');

export const fetchActiveAccounts = createAction<TGetActiveAccounts>(
  'user/fetchActiveAccounts',
);

export const fetchChangeAccountBySession = createAction<TSessionAccountProps>(
  'user/fetchChangeAccountBySession',
);

export const fetchRemoveCurrentAccountBySession = createAction<TSessionAccountProps>(
  'user/fetchRemoveCurrentAccountBySession',
);

export const fetchRemoveSession = createAction<TRemoveAllAccounts>(
  'user/fetchRemoveSession',
);

export const fetchRemoveOneAccount = createAction<TFetchRemoveOneAccount>('user/fetchRemoveOneAccount');