/* eslint-disable array-callback-return */
import React, { FC, memo } from 'react';
import { FiArrowLeft, FiLogOut, FiUser, FiUserPlus } from 'react-icons/fi';
import TooltipPoratal from 'components/UI/TooltipPoratal';
import { ResponseUser } from 'domains/user/types';
import Tooltip from 'components/UI/Tooltip';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { useChangeAccount } from './hooks/useChangeAccount';
import Account from '../Account';
import LoadingAccount from '../Account/LoadingAccount';
import css from '../../styles.module.scss';

interface IChangeAccountProps {
  user: ResponseUser | null;
  backToFront: () => void;
  logOut: () => void;
  openLoginPage: () => void;
}

const ChangeAccount: FC<IChangeAccountProps> = ({
  user,
  backToFront,
  logOut,
  openLoginPage,
}) => {
  const { t } = useTranslation();
  const {
    activeAccounts,
    isLoading,
    handleChangeAccount,
    handleExitAll,
    handleExitCurrentAccount,
    handleRemoveOneAccount,
  } = useChangeAccount({ user, logOut });

  return (
    <div className={css.change}>
      <div className={css.change_current}>
        <div className={css.back} onClick={backToFront}>
          <TooltipPoratal hint={t(`containers_page.back`)} left>
            <FiArrowLeft size={16} />
          </TooltipPoratal>
        </div>
        <div className={css.info}>
          <div className={css.info_user}>
            <div
              className={cn(css.acc_icon, {
                _isDefault: !(user && user.partner.icon),
              })}
              style={{
                backgroundImage: `url(${
                  user && user.partner.icon ? `${user.partner.icon}` : ``
                })`,
              }}
            >
              {!(user && user.partner.icon) && <FiUser size={18} />}
            </div>
          </div>
          <div className={css.info_data}>
            <span>{user && user.email}</span>
            <strong>
              {user?.partner.type} {user?.partner.title}
            </strong>
          </div>
        </div>
        <Tooltip
          title={<FiLogOut size={20} />}
          className={css.exit}
          bottom
          onClick={() => handleExitCurrentAccount(user?.email)}
        >
          {t(`profile.account.current_exit`)}
        </Tooltip>
      </div>
      <div className={css.change_other}>
        <span>{t(`profile.account.other_acc`)}</span>
        <div className={css.change_add} onClick={openLoginPage}>
          <Tooltip title={<FiUserPlus size={18} />} bottom>
            {t(`add_btn`)}
          </Tooltip>
        </div>
      </div>
      <div
        className={cn(css.change_list, {
          isLoading,
        })}
      >
        {isLoading ? (
          <LoadingAccount />
        ) : (
          activeAccounts &&
          activeAccounts.map((item) => (
            <>
              <Account
                key={item.hash}
                item={item}
                handleChangeAccount={handleChangeAccount}
                handleRemoveOneAccount={handleRemoveOneAccount}
              />
            </>
          ))
        )}
      </div>
      {activeAccounts && activeAccounts.length ? (
        <button
          type="button"
          className={css.change_exit}
          onClick={handleExitAll}
        >
          <FiLogOut size={20} />
          <span>{t(`profile.account.exit_all`)}</span>
        </button>
      ) : (
        <></>
      )}
    </div>
  );
};

export default memo(ChangeAccount);
