import { useCallback } from 'react';
import { UserState } from '../reduser';
import {
  THookSelectorFetchDataInfo,
  THookStateInfo,
  TSelector,
  useGenFetchDataSelector,
  useSelectorTypes,
} from '../../../redux/rootSelectors';
import {
  RAuthToken,
  ResponseUser,
  SettingConfig,
  TActiveAccountsInfo,
  ThemeDesign,
  TInfoByHash,
} from '../types';
import { getSettingsValue } from './utils/searhFieldIDbyName';

type TFUseIsUserNotification = () => {
  isUserNotification: boolean | null;
};

type TFUseUserTheme = () => {
  isThemeNormal: boolean;
  isThemeExtended: boolean;
  isThemeFull: boolean;
  theme: ThemeDesign;
  getValueFromSettingForKey: (key: SettingConfig['field']) => boolean | null;
  settings: ReturnType<typeof useUserSettingInfo>['data'];
};

export const selectorToken: TSelector<UserState['auth']> = ({ userReducer }) =>
  userReducer.auth;

export const selectorUser: TSelector<UserState['personalInfo']> = ({
  userReducer,
}) => userReducer.personalInfo;

export const selectorHideNavigation: TSelector<UserState['hideNavigation']> = ({
  userReducer,
}) => userReducer.hideNavigation;

export const selectorAuthResponse: TSelector<UserState['authResponse']> = ({
  userReducer,
}) => userReducer.authResponse;

export const selectorUserInfoByHash: TSelector<UserState['infoByHash']> = ({
  userReducer,
}) => userReducer.infoByHash;

export const selectorUserSettings: TSelector<UserState['settings']> = ({
  userReducer,
}) => userReducer.settings;

export const selectorActiveAccounts: TSelector<UserState['activeAccounts']> = ({
  userReducer,
}) => userReducer.activeAccounts;

/** Возвращает состояние стейта пользователя */
export const useUserInfo: THookSelectorFetchDataInfo<ResponseUser> = () =>
  useGenFetchDataSelector(selectorUser);

export const useActiveAccounts: THookSelectorFetchDataInfo<TActiveAccountsInfo[]> = () =>
  useGenFetchDataSelector(selectorActiveAccounts);

export const useIsUserNotification: TFUseIsUserNotification = () => {
  const { data, isLoading } = useUserSettingInfo();
  const isUserNotification =
    !isLoading && data && !!getSettingsValue(data, 'notification');

  return { isUserNotification };
};

export const useUserSettingInfo: THookSelectorFetchDataInfo<
  SettingConfig[]
> = () => useGenFetchDataSelector(selectorUserSettings);

/** получение состояния авторизации */
export const useSingInDate: THookSelectorFetchDataInfo<RAuthToken> = () =>
  useGenFetchDataSelector(selectorToken);

export const useHideNavigation: THookStateInfo<
  UserState['hideNavigation']
> = () => useSelectorTypes(selectorHideNavigation);

/** функция возвращает дизайн проект */
export const useUserTheme: TFUseUserTheme = () => {
  const { data: userSettings } = useUserSettingInfo();
  let theme: ThemeDesign = 'NORMAL';
  const themeValue = getSettingsValue(userSettings, 'theme');
  if (themeValue) {
    theme = themeValue as ThemeDesign;
  }

  const isThemeNormal = () => theme === 'NORMAL';

  const isThemeExtended = () => theme === 'EXTENDED';

  const isThemeFull = () => theme === 'SUPER_EXTENDED';

  const getValueFromSettingForKey:
    | ReturnType<TFUseUserTheme>['getValueFromSettingForKey']
    | null = useCallback(
    (key) => {
      if (userSettings) {
        const element = userSettings.find(({ field }) => field === key);
        if (element) {
          return !element.value;
        }
      }
      return null;
    },
    [userSettings],
  );

  return {
    isThemeNormal: isThemeNormal(),
    isThemeExtended: isThemeExtended(),
    isThemeFull: isThemeFull(),
    theme,
    getValueFromSettingForKey,
    settings: userSettings,
  };
};

export const useAppNotification = (): boolean => {
  const { data } = useUserSettingInfo();
  const app_notification = getSettingsValue(data, 'app_notification');
  if (data) {
    return !!app_notification;
  }
  return true;
};

/** Получение состояния стейта запроса */
export const useAuthResponseData: THookSelectorFetchDataInfo<boolean> = () =>
  useGenFetchDataSelector(selectorAuthResponse);

export const useUserInfoByHash: THookSelectorFetchDataInfo<TInfoByHash> = () =>
  useGenFetchDataSelector(selectorUserInfoByHash);
