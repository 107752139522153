import React, { FC, lazy, Suspense, useEffect } from 'react';
import { Switch, Redirect, Route, useLocation } from 'react-router-dom';
import Auth from 'domains/auth';
import PublicRoute from 'domains/app/PublicRouter';
import PrivateRoute from 'domains/app/PrivateRoutes';
import { removeLastId } from 'utils/useLastIdHelpers/removeLastId';
import Loader from 'components/UI/Loader';
import ControlPanel from 'views/ControlPanel';
import AdBlocker from '../Errors/AdBlocker';
import Main from '../../../views/Main';
import ConfigWrap from '../ConfigWrap';
import { useBlockedAd, useProjectInfo } from '../../project/hooks';
import history from '../../../lib/history';

const Error404 = lazy(() => import('../Errors/Error404'));
const UC = lazy(() => import('../Errors/UnderConstruction'));
const NC = lazy(() => import('../Errors/NoConnection'));

const RootRoutes: FC = () => {
  const location = useLocation();
  const { data: project, LTU } = useProjectInfo();

  removeLastId(location);

  useEffect(() => {
    if (
      location.pathname === '/error/uc' &&
      project &&
      !project.technical_works
    ) {
      history.push('/main');
    }
    if (project && project.technical_works) {
      history.push('/error/uc');
    }
  }, [LTU]);

  const { isBlocked } = useBlockedAd();

  if (isBlocked) {
    return (
      <ConfigWrap>
        <Switch>
          <Route path="/error/ad_blocker" component={AdBlocker} />
          <Redirect to="/error/ad_blocker" />
        </Switch>
      </ConfigWrap>
    );
  }

  return (
    <ConfigWrap>
      {project && project.technical_works ? (
        <Switch>
          <Route path="/error/uc" component={UC} />
          <Redirect to="/error/uc" />
        </Switch>
      ) : (
        <Switch>
          <PublicRoute path="/auth" component={Auth} />
          <PrivateRoute path="/main" component={Main} />
          <PrivateRoute path="/control_panel" component={ControlPanel} />
          <Route path="/error/404">
            <Suspense fallback={<Loader />}>
              <Error404 />
            </Suspense>
          </Route>
          <Route path="/error/uc">
            <Suspense fallback={<Loader />}>
              <UC />
            </Suspense>
          </Route>
          <Route path="/error/nc">
            <Suspense fallback={<Loader />}>
              <NC />
            </Suspense>
          </Route>
          <Redirect exact from="/" to="/main" />
          <Route>
            <Suspense fallback={<Loader />}>
              <Error404 />
            </Suspense>
          </Route>
        </Switch>
      )}
    </ConfigWrap>
  );
};
export default RootRoutes;
