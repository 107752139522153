import { useCallback, useEffect } from "react";

interface IuseClickOutsideProps {
    // ref управляющего элемента
    targetRef: React.RefObject<HTMLDivElement>;
    closePanel: () => void;
    /** Запрет на закрытие. Для исключительных случаев. */
    operationBan?: boolean;
}

export const useClickOutside = ({
    targetRef,
    closePanel,
    operationBan,
}: IuseClickOutsideProps): null => {
    const outsideClickListener = useCallback(
        (event: any) => {
            if (targetRef && targetRef.current && !operationBan) {
                if (!targetRef.current.contains(event.target)) {
                    closePanel()
                }
            }
        },
        [operationBan],
    )

    useEffect(() => {
        document.addEventListener('click', outsideClickListener);
        return () => {
            document.removeEventListener('click', outsideClickListener);
        };
    }, [targetRef, operationBan])

    return null;
};
