import React, { FC, Suspense } from 'react';
import Input from 'components/UI/Input/input';
import { useTranslation } from 'react-i18next';
import Button from 'components/UI/Buttons/buttons';
import { FiAlertCircle, FiArrowLeft } from 'react-icons/fi';
import { useSignInDetail } from 'domains/auth/hooks/useSignInDetail';
import { ReactComponent as ShowPassSvg } from 'assets/show_pass.svg';
import { ReactComponent as HidePassSvg } from 'assets/hide_pass.svg';
import { FormikProvider } from 'formik';
import TooltipPoratal from 'components/UI/TooltipPoratal';
import css from '../../styles.module.scss';

interface ILogInProps {
  backToChange: () => void;
}

const LogIn: FC<ILogInProps> = ({ backToChange }) => {
  const { t } = useTranslation();
  const {
    error,
    errors,
    touched,
    submitCount,
    values,
    handleChange,
    handleBlur,
    formikContext,
    authLoading,
    openPass,
    handleToggleOpenPass,
    submitForm
  } = useSignInDetail();

  return (
    <div className={css.login}>
      <div className={css.login_header}>
        <div className={css.back} onClick={backToChange}>
          <TooltipPoratal hint={t(`containers_page.back`)}>
            <FiArrowLeft size={16} />
          </TooltipPoratal>
        </div>
        <div className={css.login_title}>{t(`profile.account.add_acc`)}</div>
      </div>
      <FormikProvider value={formikContext}>
        <div className={css.login_form}>
          {error && (
            <div className={css.error}>
              <Suspense fallback={<></>}>
                <FiAlertCircle size={20} />
              </Suspense>
              <span>{error}</span>
            </div>
          )}
          <Input
            label={t('form.common.email')}
            error={!!submitCount && !!touched.email && !!errors.email}
            errortext={errors.email}
            inputAttributes={{
              name: 'email',
              placeholder: 'admin@gmail.com',
              value: values.email,
              onChange: handleChange,
              onBlur: handleBlur,
            }}
          />
          <Input
            label={t('form.common.password')}
            icon={
              <div onClick={handleToggleOpenPass}>
                {values.password &&
                  (openPass ? (
                    <HidePassSvg />
                  ) : (
                    <Suspense fallback={<></>}>
                      <ShowPassSvg />
                    </Suspense>
                  ))}
              </div>
            }
            icon_classname={css.toggle_pass}
            error={!!submitCount && !!touched.password && !!errors.password}
            errortext={errors.password}
            inputAttributes={{
              name: 'password',
              type: openPass ? 'text' : 'password',
              value: values.password,
              onChange: handleChange,
              onBlur: handleBlur,
              placeholder: t('form.common.password'),
            }}
          />
          <Button
            title={t('form.common.signin')}
            height={44}
            buttonAttributes={{
              type: 'submit',
              disabled: authLoading,
              onClick: submitForm
            }}
          />
        </div>
      </FormikProvider>
    </div>
  );
};

export default LogIn;
