import React, { FC, lazy, Suspense } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import Loader from 'components/UI/Loader';
import Container from './container';
import { useAuthDetail } from './hooks/useAuthDetail';
import SignIn from './SignIn';

const SignUp = lazy(() => import('./SignUp'));
const ForgotPassword = lazy(() => import('./ForgotPassword'));
const ChangePassword = lazy(() => import('./ChangePassword'));

const Auth: FC = () => {
  const { match, getToRoute } = useAuthDetail();
  return (
    <Container>
      <Switch>
        <Route exact path={`${match.path}/sign-in`} component={SignIn} />
        <Route path={`${match.path}/sign-up`}>
          <Suspense fallback={<Loader />}>
            <SignUp />
          </Suspense>
        </Route>
        <Route path={`${match.path}/forgot-password`}>
          <Suspense fallback={<Loader />}>
            <ForgotPassword />
          </Suspense>
        </Route>
        <Route path={`${match.path}/change-password`}>
          <Suspense fallback={<Loader />}>
            <ChangePassword />
          </Suspense>
        </Route>
        <Redirect from="/auth" to={getToRoute()} />
      </Switch>
    </Container>
  );
};

export default Auth;
