import { createAction } from '@reduxjs/toolkit';
import { TWssChangePageAction } from './types';

export const wssChangePage =
  createAction<TWssChangePageAction>('wss/ChangePage');

export const wssConnection = createAction('wss/connection');

export const wssCloseConnect = createAction('wss/closeConnect');

export const wssTokenRefresh = createAction('wss/tokenRefresh');

export const wssChangeStatus = createAction<boolean>('wss/ChangeStatus');

export const wssCheckBuildAndReloadPage = createAction('wss/checkBuild');
