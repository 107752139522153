import { eventChannel, SagaIterator } from '@redux-saga/core';
import {
  call,
  take,
  actionChannel,
  put,
  fork,
  cancel,
} from 'redux-saga/effects';
import { takeEvery, takeLatest } from '@redux-saga/core/effects';
import { Action } from '@reduxjs/toolkit';
import { getToken } from '../../../../lib/helpers/token';
import {
  wssChangePage,
  wssChangeStatus,
  wssCheckBuildAndReloadPage,
  wssCloseConnect,
  wssConnection,
  wssTokenRefresh,
} from '../actions';
import updateToken from '../../../../utils/updateToken';
import { WSSDataChangePage, WSSListenData } from '../types';
import { sentWssRpc, TJsonRPC } from '../../lib/send';
import { setUserList } from '../reducer';

export function InitWebSocket(url: string): WebSocket {
  const socket = new WebSocket(url);
  return socket;
}

function createWS() {
  return new Promise((res, reject) => {
    const ws = InitWebSocket('wss://wss-tdm4.adcloud.org/');
    ws.onopen = () => {
      res(ws);
      console.log('socket connected');
    };
    ws.onerror = (error) => {
      console.log(`WebSocket error ${error}`);
      console.dir(error);
      // chanel.close();
      reject(ws);
    };
  });
}

function listenWS(webSocket: WebSocket) {
  return eventChannel((emitter) => {
    const ws = webSocket;
    ws.onmessage = ({ data }) => {
      let msg: TJsonRPC<WSSListenData> | null = null;
      try {
        msg = JSON.parse(data);
      } catch (error) {
        console.error(`Error parsing : ${error.data}`);
      }
      if (
        msg?.result?.JSBuildVervion &&
        process.env.REACT_APP_BUILD_VERSION !== 'no_reload'
      ) {
        const currentVersion = process.env.REACT_APP_BUILD_VERSION;
        if (currentVersion && Number(currentVersion) < Number(msg.result.JSBuildVervion)) {
          window.location.reload();
        }
      }
      if (msg && msg.method === 'pages.updateuser') {
        return emitter(setUserList(msg.params));
      }
      return null;
    };
    ws.onclose = function (event) {
      console.log('onclose', event);
    };

    // unsubscribe function
    return () => {
      console.log('Socket off');
    };
  });
}

function workerSendWS(ws: WebSocket) {
  return function* ({ payload }: ReturnType<typeof wssChangePage>) {
    try {
      const { location, path } = payload;
      let attributes: WSSDataChangePage['attributes'] = {};
      const pathLine = location.split('/').filter((y) => y !== '');
      const campaignIndex = pathLine.findIndex((d) => d === 'campaign') + 1;
      const creativesIndex = pathLine.findIndex((d) => d === 'creatives') + 1;
      if (pathLine.find((k) => k === 'campaign')) {
        if (
          pathLine.find((k) => k === 'campaign') &&
          pathLine.find((k) => k === 'creatives')
        ) {
          attributes = {
            creative_xxhash: pathLine[creativesIndex],
            campaign_xxhash: pathLine[campaignIndex],
          };
        } else {
          attributes = { campaign_xxhash: pathLine[campaignIndex] };
        }
      }
      yield call(updateToken);
      const token = getToken();
      const data: WSSDataChangePage = {
        attributes,
        path,
        token: token?.access_token ?? '',
      };
      sentWssRpc(ws, 'pages.changepage', data);
    } catch (e) {
      console.error(e);
    }
  };
}
function workerCloseWSS(ws: WebSocket) {
  return function () {
    try {
      if (ws) {
        ws.close(1000);
      }
    } catch (e) {
      console.error({ e });
    }
  };
}

function workerRefreshTokenWSS(ws: WebSocket) {
  return function () {
    try {
      if (ws && [1].includes(ws.readyState)) {
        const token = getToken();
        sentWssRpc<string>(ws, 'token.refresh', token?.access_token ?? '');
      }
    } catch (e) {
      console.error({ e });
    }
  };
}

function workerChangeStatus(ws: WebSocket) {
  return function ({ payload }: ReturnType<typeof wssChangeStatus>) {
    try {
      if (payload) {
        sentWssRpc(ws, 'user.active', {});
      } else {
        sentWssRpc(ws, 'user.notactive', {});
      }
    } catch (e) {
      console.error({ e });
    }
  };
}

function workerCheckBuildAndReload(ws: WebSocket) {
  return function () {
    try {
      if (ws && [1].includes(ws.readyState)) {
        sentWssRpc(ws, 'system.getJSBuildVervion', {});
      }
    } catch (e) {
      console.error({ e });
    }
  };
}

// вотчер слушает события сокета и отправляет действия
function* watcherListenWs(ws: WebSocket, action: Action): SagaIterator<void> {
  const channel = yield call(listenWS, ws);
  yield takeLatest(wssChangePage, workerSendWS(ws));
  yield takeLatest(wssTokenRefresh, workerRefreshTokenWSS(ws));
  yield takeLatest(wssChangeStatus, workerChangeStatus(ws));
  yield takeLatest(wssCloseConnect, workerCloseWSS(ws));
  yield takeEvery(wssCheckBuildAndReloadPage, workerCheckBuildAndReload(ws));
  if (action.type === wssChangePage.type) yield put(action);
  while (true) {
    const actionChanel = yield take(channel);
    yield put(actionChanel);
  }
}
// основной вотчер создает сокет и передает его дальше
export default function* сс(): SagaIterator<void> {
  let ws: null | WebSocket = null;
  let task = null;

  // 2 экшена один только соеденяет другой проверяет соеденение и отправляет сообщение
  const connectionChan = yield actionChannel([
    wssConnection.type,
    wssChangePage.type,
  ]);

  while (true) {
    const action = yield take(connectionChan);
    // если нет сокета или он закрыт создаем
    if (!ws || [2, 3].includes(ws?.readyState)) {
      try {
        ws = yield call(createWS);
        if (ws) {
          // запускаем вотчер для работы с сокетом перед убивая предыдущий
          if (task) yield cancel(task);
          task = yield fork(watcherListenWs, ws, action);
        }
      } catch (e) {
        console.error({ e });
      }
    }
  }
}
