import React, { FC } from 'react';
import { FiLogOut, FiUser } from 'react-icons/fi';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ChangeSvg } from 'assets/accountChange.svg';
import css from '../../styles.module.scss';

interface IFrontProps {
  goToProfile: () => void;
  changeAccount: () => void;
  logOut: () => void;
}

const Front: FC<IFrontProps> = ({ goToProfile, changeAccount, logOut }) => {
  const { t } = useTranslation();
  const token = localStorage.getItem('$token');
  const { session_token } = token && JSON.parse(token);

  return (
    <div className={css.fornt}>
      <div className={css.item} onClick={goToProfile}>
        <FiUser color="#222" />
        <span>{t('profile.profile_word')}</span>
      </div>
      {!session_token ? (
        <div className={css.item} onClick={logOut}>
          <FiLogOut size={20} />
          <span>{t(`top.exit`)}</span>
        </div>
      ) : (
        <div className={css.item} onClick={changeAccount}>
          <ChangeSvg />
          <span>{t(`profile.account.change`)}</span>
        </div>
      )}
    </div>
  );
};

export default Front;
